import React, { useState,useEffect  } from 'react';
import { user } from "src/client/api";
import InvoiceTable from '../../invoiceTable';
import Loading from 'src/components/ui/loading';
import { MainModalHeader } from 'src/components/modals/MainModal';
import useTopbarMenu from 'src/hooks/useTopbarMenu';
import { AccountModalButton, AccountModalMenus } from './AccountModalMenus';

export default function PurchaseHistory({ type, onClickBack }) {
  const [userInvoices,setuserInvoices]=useState(null)
  const { defaultMenu, setCurrentMenu } = useTopbarMenu()
  let lastDate = ""
  
  const fetchData = async()=> {
    if( userInvoices ==null ){
      setuserInvoices( await  user.getInvoices( ) )
    }
  }
  useEffect(()=>{
    fetchData()
  },[] )

    fetchData();

    const [historyView, setHistoryView] = useState(-1)
    const view = userInvoices?.message[historyView]

  return (
    <>
      {userInvoices ? <>{historyView >= 0 ? <InvoiceTable invoices_props={userInvoices} onClickBack={onClickBack}/> : 
        <section className="flex flex-col gap-y-3">
          {userInvoices.message.map((invoice, index) => {
            const showDate = invoice.date !== lastDate;
            lastDate = invoice.date;
            return (
              <AccountModalMenus title={showDate && invoice.date}>
                <AccountModalButton
                  title={invoice.name}
                  desc={invoice.type}
                  rightText={"THB " + (invoice.total).toFixed(2)}
                  icon={invoice.icon}
                  className="p-4"
                  onClick={() => setHistoryView(index)}
                />
              </AccountModalMenus>
            );
          })}
        </section>
      }</> : <Loading />}
    </>)
}