import { Outlet, useLocation } from "react-router-dom";
import Sidebar from "../components/sidebar/sidebar";
import { useState } from "react";
import Topbar from "src/components/topbar/topbar";

export default function DashboardLayout() {
  const location = useLocation()
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const removeHorizontalPaddingOnMobile = 
    location.pathname === '/dashboard/app'

  const removeHorizontalPaddingOnSettings = 
    location.pathname === '/dashboard/settings/billing-plans'

  return (
    <>
      {location.pathname !== '/dashboard/instance-configuration' ? (
        <>
          <Sidebar />
          <Topbar />
          <div className={`page-section`}>
            <Outlet />
          </div>
        </>
      ) : <Outlet />}
    </>
  );
}