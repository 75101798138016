import TeamMembers from "src/pages/teams/teammembers";
import Overview from "./Overview";
import PurchaseHistory from "./PurchaseHistory";
import AllTeams from "src/pages/teams/allteams";
import Teams from "src/pages/teams/teams";
import Checkout from "./Checkout";
import Upgrade from "./Upgrade";
import BillingAddress from "./BillingAddress";
import MyAccountForm from "./MyAccountForm";

export const ACCOUNT_MENUS = [
  {
    title: "My Account",
    key: "My Account",
    component: MyAccountForm,
    group: ""
  },
  {
    title: "Overview",
    key: "Overview",
    component: Overview,
    group: "billing"
  },
  {
    title: "Unpaid Invoice",
    key: "Unpaid Invoice",
    component: (() => <h1>UNPAID INVOICE</h1>),
    group: "billing"
  },
  {
    title: "Purchase history",
    key: "Purchase history",
    component: PurchaseHistory,
    group: "billing"
  },
  {
    title: "Billing address",
    key: "Billing address",
    component: BillingAddress,
    group: "billing"
  },
  {
    title: "Usages",
    key: "Usages",
    component: (() => <h1>USAGES</h1>),
    group: "billing"
  },
  {
    title: "Invite Team Members",
    key: "Invite Team Members",
    component: Teams,
    group: "team_members"
  },
  {
    title: "Manage Team Members",
    key: "Team Members",
    component: TeamMembers,
    group: "team_members"
  },
  {
    title: "Switch WorkSpace",
    key: "All your teams",
    component: AllTeams,
    group: "team_members"
  },
  {
    title: "Logout",
    component: null,
    group: "logout"
  },
  {
    title: "Upgrade",
    key: "Upgrade",
    component: Upgrade,
    group: "upgrade"
  },
  {
    title: "Checkout",
    key: "Checkout",
    component: Checkout,
    group: "upgrade"
  }
]