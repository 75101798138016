import { BillingAddressForm } from "src/components/billingAddressForm";
import useTopbarMenu from "src/hooks/useTopbarMenu";
import { useUser } from "src/hooks/useUser";

const BillingAddress = ({ setCurrentMenu, onSubmitForm }) => {

  const { key } = useTopbarMenu();

  const { user, team } = useUser();
  return (
    <BillingAddressForm 
      billingAddress={team}
      onSubmitForm={() => setCurrentMenu('Account')}
    />
  )
}

export default BillingAddress