import { DataList, Pagination } from "./pagination";
import { Table, TableBody, TableCaption, TableCell, TableHead, TableHeader, TableRow } from "./ui/table"
import React, { useState, useEffect } from 'react';
import { Separator } from "./ui/separator";
import { useTranslation } from "react-i18next";
import { Button } from "./ui/button";
import { Download } from "lucide-react";
import { AccountModalButton, AccountModalMenus } from "./topbar/components/AccountModalMenus";
import UpgradeLogo from "./icon-menus/UpgradeLogo";
import { MainModalHeader } from "./modals/MainModal";
import { useNavigate } from 'react-router-dom';
import { site } from "src/client/api";
import { useMutation, useQuery } from "react-query";
import { useUser } from "src/hooks/useUser";

export default function InvoiceTable({ invoices_props }) {
  const { t } = useTranslation()
  let lastDate = ""
  const navigate = useNavigate();
  const { data: sites } = useQuery('sites', site.list, { enabled: false });
  const { user } = useUser()

  useEffect(() => {
    console.log(invoices_props)
  }, [])

  const paymentStatus = (status) => {
    switch (status) {
      case "Unpaid": return t("Unpaid")
      case "Paid": return t("Paid")
      case "Draft": return t("Draft")
      default: return
    }
  }

  const formatDate = (expiry) => {
    if (!expiry) return ''; // Return an empty string if no expiry date
    const date = new Date(expiry);
    return date.toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' });
  };

  // const TableData = ({name, status,expiry, formatted_total}) => {
  //   return (
  //     <TableRow key={name}>
  //       <TableCell className="font-medium">{name}</TableCell>
  //       <TableCell>{paymentStatus(status)}</TableCell>
  //       <TableCell>{t('paid_via_qr')}</TableCell>
  //       <TableCell>{formatExpiryDate(expiry)}</TableCell>
  //       <TableCell className="text-right">฿ {formatted_total}</TableCell>
  //     </TableRow>
  //   )
  // }

  const [historyView, setHistoryView] = useState(-1)
  const view = invoices_props.message[historyView]

  return (
    <>
      {/* <div className="table-invoice-desktop">
        <Table>
          <TableHeader>
            <TableRow className="hover:bg-transparent">
              <TableHead className="w-[240px]">{t('settings.purchase_history.invoice')}</TableHead>
              <TableHead>{t('settings.purchase_history.status')}</TableHead>
              <TableHead>{t('settings.purchase_history.method')}</TableHead>
              <TableHead>{t('settings.purchase_history.expiry')}</TableHead>
              <TableHead className="text-right">{t('settings.purchase_history.amount')}</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {invoices_props.message.map((invoice) => (
              <TableData expiry={invoice.period_end} status={invoice.status} name={invoice.name} formatted_total={invoice.total}/>
            )).slice((currentPage - 1) * listPerPage, currentPage * listPerPage)}
          </TableBody>
        </Table>
      </div> */}

      <section className="flex flex-col gap-y-3">
        <AccountModalMenus className="px-4 py-6 flex flex-col gap-y-5 mb-7">
          <div className="flex items-center justify-between text-xl font-bold">
            <h3 className="flex items-center gap-x-2">
              {"Zaviago"}
              <UpgradeLogo />
            </h3>
            <p>THB {(view.total).toFixed(2)}</p>
          </div>

          <div className="flex items-center justify-between">
            <div>
              <h3 className="font-semibold text-sm">WorkSpace Pro Plan</h3>
              <p className="text-lightgray-900/60 text-xs">For {sites ? sites?.site_list.find(site => site.name.includes(process.env.REACT_APP_SITE_DOMAIN))?.name : ''}</p>
            </div>
            <p className="text-sm">THB 75.00</p>
          </div>

          {/* {view.purchase_info.map(info => (
            <div className="flex items-center justify-between">
              <div>
                <h3 className="font-semibold text-sm">VAT charged at 7%</h3>
                <p className="text-lightgray-900/60 text-xs">Shopee / Lazada / TikTok</p>
              </div>
              <p className="text-sm">THB 75.00</p>
            </div>
          ))} */}

          <div className="flex items-center justify-between">
            <h3>VAT charged at 7%</h3>
            <p className="text-sm">THB {(view.total * 0.07).toFixed(2)}</p>
          </div>
          <div className="flex items-center justify-between">
            <h3>Total</h3>
            <p className="text-sm">THB {(view.total).toFixed(2)}</p>
          </div>
        </AccountModalMenus>

        <AccountModalMenus className="px-4 py-6 flex flex-col gap-y-5">
          <div className="grid grid-cols-2">
            <div>
              <h2 className="text-lightgray-900/60 text-sm">Order Date</h2>
              <p>{formatDate(view.date)}</p>
            </div>
            <div>
              <h2 className="text-lightgray-900/60 text-sm">Order ID</h2>
              <p>{view.name}</p>
            </div>
          </div>

          <div>
            <h2 className="text-lightgray-900/60 text-sm">Zaviago Account</h2>
            <p>{user.email}</p>
          </div>

          <div>
            <h2 className="text-lightgray-900/60 text-sm">Billing address</h2>
            <p>{view.billing_address || <>
              Zaviago.co.ltd<br />
              999/99 rama9, bangtrading group<br />
              Floor 2<br />
              Suanluang Bangkok 10250<br />
              Thailand
            </>}</p>
          </div>
        </AccountModalMenus>
        <Button className="regis-btn !max-w-none !w-full">Download Invoice</Button>
      </section>
    </>
  )
}