import { Table, TableBody, TableCell, TableRow } from "src/components/ui/table";
import { MainModal, MainModalHeader } from "src/components/modals/MainModal";
import { ChevronRight } from "@untitled-ui/icons-react/build/cjs";
import { cn } from "src/lib/utils";
import { useLocation, useSearchParams } from "react-router-dom";
import useTopbarMenu from "src/hooks/useTopbarMenu";

export function AccountModalMenus({ title, largeTitle, desc, menus, onClick, onSelect, type, children, className }) {
  return (
    <fieldset>
      {(title || desc) ? (
        <div className="px-4 pb-[7px] text-lightgray-900/60 leading-4">
          {title ? <legend className="text-xs">{title}</legend> : null}
          {desc ? <p className="font-light text-xxs pb-[5px]">{desc}</p> : null}
        </div>
      ) : null}

      {largeTitle ? <h2 className="px-4 pb-3 text-[20px] font-medium">{largeTitle}</h2> : null}

      <section className={cn("bg-lightgray-100 rounded-lg", className)} onClick={onClick}>
        {menus ? (
          <Table>
            <TableBody>
              {menus.map(menu => (
                <TableRow className="cursor-pointer" onClick={() => onSelect(menu)}>
                  <TableCell className={cn("pl-0 pr-2 py-3 flex items-center w-full font-normal text-black text-base", "gap-x-3", { "justify-between": type !== "checkbox" })}>
                    {menu.title}
                    <ChevronRight className="text-lightgray-900/30 w-5 h-5" />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : null}

        {children}
      </section>
    </fieldset>
  )
}

export const AccountModalButton = ({ title, icon, desc, rightText, onClick, className, titleClassName }) => {
  return (
    <div className={cn("justify-between flex cursor-pointer", className)} onClick={onClick}>
      <section className="flex items-center gap-x-4">
        {icon}
        <div>
          <h3 className={cn("text-black text-base text-left", titleClassName)}>{title}</h3>
          <p className="text-lightgray-900/60 text-sm text-left">{desc}</p>
        </div>
      </section>
      <div className="flex items-center gap-x-2">
        <p className="text-lightgray-900 text-sm">{rightText}</p>
        <ChevronRight className="text-lightgray-900/30" />
      </div>
    </div>
  )
}

export const AccountModal = ({ open, onClose, title, onClickBack, component, onClickRightButton }) => {
  const location = useLocation();
  let [_, setSearchParams] = useSearchParams();
  return (
    <MainModal
      onClose={() => {
        // setSearchParams({});
        if (onClose) onClose(false);
      }}
      open={open}
      mainClassName="sf-pro p-6 md:px-[74px] md:py-12"
    >
      <MainModalHeader
        title={title}
        onClickBack={(params) => {
          // setSearchParams({});
          if (onClickBack) onClickBack(params);
        }}
        rightButton="Done"
        onClickRightButton={() => {
          // setSearchParams({});
          onClickRightButton ? onClickRightButton() : onClose(false)
        }}
      />
      {component}
    </MainModal>
  )
}