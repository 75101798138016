import React, { useEffect, useRef, useState } from 'react'
import AvatarMenu from './avatarMenu'
import { useTranslation } from 'react-i18next'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useMutation, useQuery } from "react-query";
import UpgradeProButton from './upgradeProButton'
import { site } from "../../client/api";
import { Skeleton } from "../ui/skeleton"
import { motion } from 'framer-motion';
import { Button } from '../ui/button'
import useTopbarMenu from 'src/hooks/useTopbarMenu'
import { AccountModal } from './components/AccountModalMenus'
import { Icons } from '../ui/icons'
import { ChevronDown } from 'lucide-react'
import { usePostHog } from 'posthog-js/react';
import { useUser } from 'src/hooks/useUser';

export default function Topbar() {

  const { t } = useTranslation()
  const { user, auth, logout } = useUser();
  const posthog = usePostHog();
  const { data: sites } = useQuery('sites', site.list, {
    enabled: !!user,
  });

  const location = useLocation()
  const navigate = useNavigate()
  const params = new URLSearchParams(location.search);

  const { isModalOpen, setIsModalOpen, handleAccountModal, renderComponent, key, handleBack } = useTopbarMenu();
  const [currentLocation, setCurrentLocation] = useState(0);

  const { data: loadAdmin, refetch } = useQuery('loadAdmin', () => site.login(sites?.site_list.find(site => site.name.includes(process.env.REACT_APP_SITE_DOMAIN))?.name, 'Admin'), {
    enabled: false,
    onSuccess: (res) => {
      //console.log(res);
    },
  });

  useEffect(() => {
    if (user && sites?.site_list.find(site => site.name.includes(process.env.REACT_APP_SITE_DOMAIN))?.name && !loadAdmin) {
      refetch();
    }
  }, [user, sites, refetch]);

  const OpenPage = (page, app = "app") => {
    // var sid = loadAdmin?.data?.message.sid;
    var sitetoview = sites?.site_list.find(site => site.name.includes(process.env.REACT_APP_SITE_DOMAIN))?.name;
    window.open(`https://${sitetoview}/${app}/${page}`, '_blank');
  }

  const menus = [
    { title: "Today", link: "/dashboard/app"},
    { title: "WorkSpace", link: "/dashboard/workspace"},
    { title: "My Storefront", onClick: () => OpenPage("", "store")},
    { title: "Teams", onClick: () => { handleAccountModal("Manage Team Members") } }
  ]

  useEffect(() => {
    if (params.get('modal') === 'team' && !isModalOpen) {
      handleAccountModal("Manage Team Members")
    }
  }, [])

  const ActiveMenu = ({ width, left }) => {
    return (
      <motion.div
        animate={{
          x: left,
          y: 0,
          scale: 1,
          rotate: 0,
        }}
        style={{
          width: width,
          height: "40px",
          backgroundColor: "white",
          borderRadius: "9999px",
          position: "absolute"
        }} />
    )
  }

  return (
    <nav className="topbar">
      <div className="flex items-center gap-x-3 relative cursor-pointer col-span-1" onClick={() => handleAccountModal()}>
        <Icons.mainIcon className="rotate-180 h-7 w-7" />
        <p className={`text-xs text-lightgray-500 flex items-center gap-2 sf-pro tracking-[-0.43px]`}>
          {sites ? sites?.site_list.find(site => site.name.includes(process.env.REACT_APP_SITE_DOMAIN))?.name : ''} {/* <Skeleton className='h-3 w-full rounded-sm'/> - WILL USE THE SKELETON LATER */}
          <ChevronDown className="w-4 h-4" />
        </p>
      </div>
      <section className='flex justify-center col-span-4'>
        <div className='flex items-center bg-lightgray-100 rounded-full p-1 font-medium relative w-fit gap-2 h-[46px] overflow-hidden'>
          {menus.map((menu, index) => {
            return (
              <Button
                key={index}
                onClick={() => menu.onClick ? menu.onClick() : navigate(menu.link)}
                className={`px-5 py-3 leading-4 shadow-none !bg-transparent relative rounded-full z-5 text-base font-semibold ${(menu.link === "/dashboard/workspace" ? location.pathname.includes('workspace') : location.pathname === menu.link) ? 'text-lightblue !bg-white shadow-topbar' : 'text-black'}`}
              >{menu.title}</Button>
            )
          })}
          <UpgradeProButton />

          {/* <ActiveMenu width="60px" left="0"/> */}
        </div>
      </section>
      <section className='flex justify-end col-span-1'>
        <AvatarMenu />
      </section>
      <AccountModal
        open={isModalOpen}
        onClose={setIsModalOpen}
        title={key}
        onClickBack={handleBack} 
        component={renderComponent()}
      />
    </nav>
  )
}