import { useState, useEffect, useContext } from 'react'
import { useUser } from "../../../hooks/useUser";
import { useMutation, useQuery } from "react-query";
import { site } from "../../../client/api";
import { useNavigate } from "react-router-dom";
import { Progress } from "src/components/ui/progress";
import { Table, TableBody, TableCell, TableRow } from "src/components/ui/table";
import UpgradeLogo from "src/components/icon-menus/UpgradeLogo";
import { Skeleton } from "src/components/ui/skeleton";
import { useTranslation } from "react-i18next";
import { AccountModalButton, AccountModalMenus } from "src/components/topbar/components/AccountModalMenus";
import { Icons } from "src/components/ui/icons";
import { ChevronRight } from "@untitled-ui/icons-react/build/cjs";
import TeamMemberIcon from "src/components/icon-menus/TeamMemberIcon";
import OverviewMenu from './OverviewMenu';

export default function Overview({ setCurrentMenu }) {
  const { t } = useTranslation()
  const [numOfAdmin, setNumOfAdmin] = useState(0)
  const [installdApps, setinstalldApps] = useState(0)
  const [doctypeList, setdoctypeList] = useState([])
  const navigate = useNavigate();

  const { user, auth, logout } = useUser();
  const { data: sites } = useQuery('sites', site.list, {
    enabled: !!user,
  });

  const checkout_info = useQuery('checkout_info', () => site.get_web_plans(sites?.site_list.find(site => site.name.includes(process.env.REACT_APP_SITE_DOMAIN))?.name), { enabled: false });
  const pro_details = checkout_info?.data && checkout_info?.data.find(item => item.name === 'pro');
  const plan_change_logs = useQuery('plan_change_logs', () => site.get_plan_change_logs(sites.site_list.find(site => site.name.includes(process.env.REACT_APP_SITE_DOMAIN))?.name), { enabled: false });

  useEffect(() => {
    if (auth?.onboarding.site_created === false || (sites && !sites?.site_list.find(site => site.name.includes(process.env.REACT_APP_SITE_DOMAIN)))) {
      navigate('/dashboard/instance-configuration');
    }
    if (user && sites?.site_list.find(site => site.name.includes(process.env.REACT_APP_SITE_DOMAIN))?.name && !checkout_info.data) {
      checkout_info.refetch();
    }
    if (user && sites?.site_list.find(site => site.name.includes(process.env.REACT_APP_SITE_DOMAIN))?.name && !plan_change_logs.data) {
      plan_change_logs.refetch();
    }
  }, [auth?.onboarding.site_created, checkout_info, plan_change_logs, sites]);

  const { data: siteOverview } = useQuery(['site', `${sites?.site_list.find(site => site.name.includes(process.env.REACT_APP_SITE_DOMAIN))?.name}`], () => site.overview(sites?.site_list.find(site => site.name.includes(process.env.REACT_APP_SITE_DOMAIN))?.name), {
    enabled: !!sites?.site_list.length,
    onSuccess: (res) => {
      fetchUsageData(res.domains[0].name);
    }
  });

  const fetchUsageData = async (domain) => {
    try {
      const response = await fetch(`https://${domain}/api/method/erpnext_quota.erpnext_quota.quota.get_list_of_usage`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      const dataArray = data.message;
      setNumOfAdmin(dataArray['admin_user_count']);
      setinstalldApps(dataArray['installed_apps_count']);
      setdoctypeList(dataArray['doctype_limit']);
    } catch (error) {
      console.error('Error fetching usage data:', error);
    }
  };

  const { mutate: loginAsAdmin } = useMutation('loginAsAdmin', ({ name, reason }) => site.login(name, reason), {
    onSuccess: (res) => {
      const { sid, site } = res.data.message;
      if (sid && site) {
        window.open(`https://${site}/app/home?sid=${sid}`, '_blank');
      }
    }
  });

  const plan = siteOverview?.plan?.current_plan;
  const installedApps = useQuery('installed_apps', () => site.installed_apps(sites.site_list.find(site => site.name.includes(process.env.REACT_APP_SITE_DOMAIN))?.name), { enabled: false });
  const usagePlan = siteOverview?.plan
  const checkIfPro = plan?.name === 'pro'

  const formatDate = (dateString) => {
    if (!dateString) return ''; // Handle empty or undefined date
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { day: '2-digit', month: 'short', year: 'numeric' });
  };

  const overview_menus = {
    upgrade: {
      title: "Upgrade plan",
      submenus: [
        {
          title: checkIfPro ? "Get Pro+" : "Get More Storage and Features",
          desc: "Get more for your business and features with zaviago pro plan",
          icon: (<UpgradeLogo />)
        },
        {
          title: (<span className="text-link">Upgrade to Pro</span>),
          desc: "Starting from THB 750.00/month",
          icon: null
        }
      ]
    },
    team: {
      title: "Team member",
      submenus: [
        {
          title: (checkIfPro ?
            <>You have <span className="text-darkpurple">5 Pro members</span> and <span className='text-[#56C741]'>10 Pro seats</span> available</> :
            <>You have <span className="text-darkpurple">10 free team members</span></>
          ),
          desc: (checkIfPro ?
            "Get more member seats by adding more seat to your workspace" :
            "Add unlimited free team member to use with your free forever package"
          ),
          icon: (<TeamMemberIcon />)
        },
        {
          title: (<span className="text-link">{checkIfPro ? "Add more seat starting at THB 350.00" : "Invite and share"}</span>),
          desc: checkIfPro ?
            "Per member / month" :
            "Share your workspace with other people for free and work together."
          ,
          icon: null
        }
      ]
    }
  }

  const { upgrade, team } = overview_menus

  return (
    <>
      <section className="flex flex-col gap-y-10">
        <AccountModalMenus title="SUBSCRIPTION FOR YOUR TEAM" desc="Active Plan">
          <AccountModalButton
            className="p-4"
            icon={<Icons.mainIcon />}
            title={checkIfPro ? 'Pro Package' : 'Free Trial'}
            titleClassName={checkIfPro ? '' : "font-medium"}
            rightText={`THB ${checkIfPro && plan ? (plan.price_usd).toFixed(2) : '0.00'}`}
            onClick={() => checkIfPro ? {} : setCurrentMenu("Upgrade")}
            desc={<>{checkIfPro ? formatDate(usagePlan?.active_invoice?.[0]?.period_end) || "31 Dec 2025" : "No expired date."}<br />{sites?.site_list.find(site => site.name.includes(process.env.REACT_APP_SITE_DOMAIN))?.name || "test.zaviago.com"}</>}
          />
        </AccountModalMenus>
        <OverviewMenu title={upgrade.title} submenu={upgrade.submenus} />
        <OverviewMenu title={team.title} submenu={team.submenus} />
        <AccountModalMenus className="px-3 py-2" title="PLAN USAGE" desc={plan ? `Updated on ${new Date(plan?.modified).toISOString().slice(0, 16).replace('T', ' ')}` : ''}>
          <section>
            {doctypeList.length > 0 ? doctypeList.map(p => (
              <div className="flex items-center justify-between px-4 py-3 gap-4">
                <h3 className="w-1/3">{p.doctype_name}</h3>
                <Progress value={(p.quota_usage / p.quota_limit) * 100} />
                <div className="flex items-center gap-x-2 w-1/4 justify-end">
                  <p className="text-lightgray-900 text-sm">{p.quota_usage}/{p.quota_limit}</p>
                  <ChevronRight className="text-lightgray-900/30" />
                </div>
              </div>
            )) : <p className='px-4'>No plan usage</p>}
          </section>
        </AccountModalMenus>
        {/* <div className="text-desc flex gap-x-4 items-center mt-10">
          {plan ? (
            <>
              <p className="flex items-center gap-x-1 text-sm"><CheckCircledIcon className="h-3 w-3" /> {t('settings.overview.current_plan')}</p>
              <p className="flex items-center gap-x-1 text-sm"><MagicWandIcon className="h-3 w-3" />{plan?.name === 'pro' ? t('settings.overview.now_in_pro')+plan?.price_usd+"/mon" : t('settings.overview.up_to_pro')+pro_details?.price_usd+"/mon"}</p>
            </>
          ) : (
            <Skeleton className='h-3 w-full' />
          )}
        </div> */}
      </section >

      {/* <section>
        <div className="flex justify-between">
        {plan ? (
          <div>
            <h1 className="text-3xl font-semibold font-inter">฿ {plan.price_usd}</h1>
            <div className="flex items-center gap-x-[6px]">
              <p className="text-base leading-7 text-secondary">
                {plan.name === 'pro' 
                  ? `${t('settings.overview.renewal')} }` /* ${formatDate(usagePlan?.active_invoice?.[0]?.period_end)
                  : t('settings.overview.free_forever')}
              </p>

              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <AlertCircle className="h-4 w-4" />
                  </TooltipTrigger>
                  <TooltipContent>
                    {plan.name === 'pro' 
                      ? `${t('settings.overview.plan_pro_desc')} ` /* ${formatDate(usagePlan?.active_invoice?.[0]?.period_end)}
                      : t('settings.overview.plan_free_desc')}
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>
          </div>
        ) : (
          <div className="flex flex-col gap-y-2">
            <Skeleton className='h-9 w-[100px]' />
            <Skeleton className='h-4 w-[300px]' />
          </div>
        )}
          
        </div>
      </section> */}

      {/* {plan ? (
        <section>
          <h1 className="secondary-heading">{t('plan_usage.title')}</h1>
          <p className="main-desc">
            {t('plan_usage.updated_on')} {} {/* new Date(plan?.modified).toISOString().slice(0, 16).replace('T', ' ') 
          </p>

          <div className="text-desc flex gap-x-4 items-center my-6">
            <p className="flex items-center gap-x-1 text-sm">
              <Users viewBox="0 0 24 24" width='16' height='16' />
              {localStorage.lang === "th" ? (
                <>{t('plan_usage.admin_user_prefix')} {numOfAdmin} {t('plan_usage.admin_user_suffix')}</>
              ) : (
                <>{numOfAdmin} {numOfAdmin === 1 ? t('plan_usage.admin_user') : t('plan_usage.admin_users')}</>
              )}
            </p>
            <p className="flex items-center gap-x-1 text-sm">
              <LayoutGrid viewBox="0 0 24 24" width='16' height='16' />
              {localStorage.lang === "th" ? (
                <>{t('plan_usage.installed_app_prefix')} {numOfAdmin} {t('plan_usage.installed_app_suffix')}</>
              ) : (
                <>{installdApps || 0} {installdApps === 1 ? t('plan_usage.installed_app') : t('plan_usage.installed_apps')}</>
              )}

            </p>
          </div>

          <div className="flex flex-col gap-y-8 md:gap-y-4">

          {doctypeList?.map(p => (
            <>
              <div className="hidden md:flex items-center gap-x-4">
                <h2 className="subheading font-medium w-1/4">{p.doctype_name}</h2>

                <div className="w-full flex gap-x-[11px] items-center">
                  <Progress value={(p.quota_usage / p.quota_limit) * 100} />
                  <span className="text-xs inline-block w-[40px]">({(p.quota_usage / p.quota_limit) * 100}%)</span>
                </div>

                <p className="main-desc w-1/4 text-right">{p.quota_usage} / {p.quota_limit}</p>
              </div>

              <div className="md:hidden flex flex-col items-center gap-x-4 gap-y-1">
                <div className="flex items-center w-full justify-between">
                  <h2 className="subheading font-medium">{p.doctype_name}</h2>

                  <div className="w-fit flex items-center gap-x-2">
                    <span className="text-xs inline-block w-[40px]">({(p.quota_usage / p.quota_limit) * 100}%)</span>
                    <p className="main-desc">{p.quota_usage} / {p.quota_limit}</p>
                  </div>
                </div>

                <Progress value={(p.quota_usage / p.quota_limit) * 100} />
              </div>
            </>
          ))}


          </div>
        </section>
      ) : (
        <div className="flex flex-col gap-y-4">
          <Skeleton className='h-4 w-full' />
          <Skeleton className='h-4 w-full' />
          <Skeleton className='h-4 w-full' />
        </div>
      )} */}
    </>
  )
}