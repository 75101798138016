import { user as user_api } from "src/client/api";
import * as yup from "yup"
import { ErrorMessage, useFormik } from 'formik';
import { Input } from "./ui/input";
import { Button } from "src/components/ui/button";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectGroup, SelectValue } from "src/components/ui/select";
import { useState, useEffect } from 'react';
import { provinces, provincesTh } from "src/components/form-controls/provinces";
import { Loader2, X } from "lucide-react";
import { useToast } from "./ui/use-toast";
import { useTranslation } from "react-i18next";
import { AccountModalMenus } from "./topbar/components/AccountModalMenus";
import InputSection from "./InputSection";
import { Table, TableBody } from "./ui/table";

export function BillingAddressForm({ billingAddress, onSubmitForm, submitText, onSettings, onTypeChange, onChangeWHT }) {
  const [isCompany, setIsCompany] = useState(false);
  const [enableTaxID, setEnableTaxID] = useState(onSettings ? true : false);
  const [saving, setSaving] = useState(false);
  const { toast } = useToast();
  const { t, i18n } = useTranslation();
  const [isInitialValuesSet, setIsInitialValuesSet] = useState(false);
  const [deductWHT, setDeductWHT] = useState(false);

  // Initialize formik
  const formik = useFormik({
    initialValues: {
      // fullname: '',
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      company_name: '',
      deduct_wht: '',
      address: '',
      subdistrict: '',
      district: '',
      province: 'Bangkok',
      postal_code: '',
      tax_id: '',
      tax_invoice_type: 'Personal',
    },
    onSubmit: async (values) => {
      setSaving(true);
      await onSubmitForm(values);  // Pass the form values to the parent
      setSaving(false);  // Handle the saving state
    },
  });
  useEffect(() => {
    if (billingAddress && !isInitialValuesSet) {
      formik.setValues({
        // fullname: billingAddress?.billing_name || '',
        firstname: billingAddress?.first_name || '',
        lastname: billingAddress?.last_name || '',
        email: billingAddress?.email || '',
        phone: billingAddress?.phone || '',
        company_name: billingAddress?.billing_name || '',
        deduct_wht: billingAddress?.deductwht || '',
        address: billingAddress?.address?.address_line1 || '',
        subdistrict: billingAddress?.address?.address_line2 || '',
        district: billingAddress?.address?.city || '',
        province: billingAddress?.address?.state || '',
        postal_code: billingAddress?.address?.pincode || '',
        tax_id: billingAddress?.address?.gstin || '',
        tax_invoice_type: billingAddress?.tax_invoice_type || '',
      });
      if (billingAddress?.tax_invoice_type == "Company") {
        setIsCompany(true);
      }
      if (billingAddress?.deductwht) {
        setDeductWHT(true);
      }
      setIsInitialValuesSet(true); // Set the flag to prevent further updates
    }
  }, [billingAddress, isInitialValuesSet]);


  const fields = {
    addresses: ['address', 'subdistrict', 'district', 'postal_code', 'province'],
    personal: ['firstname', 'lastname'],
    company: ['company_name', 'tax-id']
  }

  const handleChangeValue = (e, name) => {
    formik.handleChange(e);
    formik.setFieldTouched(name, true)
  }

  const validateValue = (val, message = "") => {
    const cond = formik.touched[val] && !formik.values[val]

    return message ?
      (cond ? <p className="error fade-in-validation">{t(message)}</p> : "") :
      (cond ? "border border-error" : "")
  }

  const { addresses, personal, company } = fields

  const [requiredFields, setRequiredFields] = useState([...personal, ...addresses])
  const checkIfFilledAll = requiredFields.every(field => formik.values[field] !== "");

  useEffect(() => {
    isCompany ?
      setRequiredFields([...company, ...addresses]) :
      setRequiredFields([...personal, ...addresses])
  }, [isCompany, formik.values, checkIfFilledAll])
  useEffect(() => {
    if (onTypeChange) {
      onTypeChange(isCompany);
    }

    if (onChangeWHT) {
      onChangeWHT(deductWHT);
    }
  }, [isCompany, onTypeChange, deductWHT]);

  const invoice_type = ["Personal", "Company"]

  const FORM_BILLING_ADDRESS = [
    { type: "text", placeholder:"First name", label:"First name", name:"first_name", value: formik.values.first_name || ''},
    { type: "text", placeholder:"Last name", label:"Last name", name:"last_name", value: formik.values.last_name || ''},
    { type: "email", placeholder:"Email", label:"Email", name:"email", value: formik.values.email || ''},
    { type: "tel", placeholder:"Phone", label:"Phone", name:"phone", value: formik.values.phone || ''},
    { type: "text", placeholder:"Address", label:"Address", name:"address", value: formik.values.address || ''},
    { type: "text", placeholder:"Sub-district", label:"Sub-district", name:"subdistrict", value: formik.values.subdistrict || ''},
    { type: "text", placeholder:"District", label:"District", name:"district", value: formik.values.district || ''},
    { type: "select", placeholder:"Province", label:"Province", name:"province", value: formik.values.province || '', 
      value: formik.values.province,
      onValueChange: value => formik.setFieldValue('province', value),
      options: (i18n.language == "en" ? provinces : provincesTh).map(province => <SelectItem value={province} key={province}>{province}</SelectItem>)
    },
    { type: "text", placeholder:"Postal code", label:"Postal code", name:"postal_code", value: formik.values.postal_code || ''},
  ]

  const FORM_COMPANY_INFO = [
    { type: "select", placeholder:"Tax Invoice Type", label:"Tax Invoice Type", name:"tax_invoice_type",
      value: formik.values.tax_invoice_type,
      onValueChange: value => {
        formik.setFieldValue("tax_invoice_type", value);
        setIsCompany(value === 'Company');
      },
      options: invoice_type.map(invoice => <SelectItem value={invoice}>{invoice}</SelectItem>)
    },
    { type: "text", placeholder:"Company name", label:"Company name", name:"company_name", value: formik.values.company_name || ''},
    { type: "tel", placeholder: isCompany ? "3-0000-9999-9" : "1-1009-99999-09-9", label:"Tax ID", name:"tax_id", value: formik.values.tax_id || ''},
  ]

  return (
    <form onSubmit={formik.handleSubmit} className="flex flex-col gap-10">
      <AccountModalMenus title="BILLING INFORMATION" desc="Update / Edit your personal information" className="pl-4">
        <Table>
          <TableBody>
            {FORM_BILLING_ADDRESS.map(inp => (
              <InputSection 
                type={inp.type} 
                placeholder={inp.placeholder} 
                label={inp.label}
                name={inp.name} 
                value={inp.value}
                onChange={formik.handleChange}
                onValueChange={inp.onValueChange || null}
                options={inp.options || null}
              />
            ))}
          </TableBody>
        </Table>
      </AccountModalMenus>

      <AccountModalMenus className="pl-4">
        <Table>
          <TableBody>
            {FORM_COMPANY_INFO.map(inp => (
              <InputSection 
                type={inp.type} 
                placeholder={inp.placeholder} 
                label={inp.label}
                name={inp.name} 
                value={inp.value}
                onChange={formik.handleChange}
                onValueChange={inp.onValueChange || null}
                options={inp.options || null}
              />
            ))}
          </TableBody>
        </Table>
      </AccountModalMenus>

      {/* WILL REMOVE SOON AFTER THIS COMPONENT IS DONE */}
      {/* <form className="flex flex-col gap-y-6 md:gap-y-8 mt-8" onSubmit={formik.handleSubmit}>
        <div className="space-y-6 w-full">
          <div className="anim-up flex flex-col">
            <label className="subheading mb-2 font-medium">
              {t('tax_invoice_type')} <span className="text-error">*</span>
            </label>
            <Select
              name="tax_invoice_type"
              defaultValue={formik.values.tax_invoice_type}
              onValueChange={value => {
                formik.setFieldValue("tax_invoice_type", value);
                setIsCompany(value === 'Company');
              }}
            >
              <SelectTrigger className="w-full">
                <SelectValue>{formik.values.tax_invoice_type}</SelectValue>
              </SelectTrigger>
              <SelectContent>
                <SelectItem value='Personal'>{t('billing_personal')}</SelectItem>
                <SelectItem value='Company'>{t('billing_company')}</SelectItem>
              </SelectContent>
            </Select>
          </div>
        </div>

        {isCompany ? (
          <div className="space-y-6">
            <div className="anim-up flex flex-col">
              <label className="subheading mb-2 font-medium">
                {t('company_name')} <span className="text-error">*</span>
              </label>
              <Input
                placeholder={t('company_name')}
                name="company_name"
                type='text'
                className={validateValue("company_name")}
                onChange={(e) => handleChangeValue(e, "company_name")}
                value={formik.values.company_name}
              />
              {validateValue("company_name", "Company name is required")}
            </div>

            <div className="anim-up flex items-center">
              <input
                id="deductWHT"
                type="checkbox"
                name="deduct_wht"
                onChange={event => {
                  const value = event.target.checked;
                  formik.setFieldValue("deduct_wht", value);
                  setDeductWHT(value);
                }}
                checked={formik.values.deduct_wht}
              />
              <label htmlFor="deductWHT" className="subheading mb-2 font-medium">
                {t('Deduct WHT 3%?')}
              </label>
            </div>

          </div>
        ) : (
          <div className="flex flex-col md:flex-row gap-y-6 gap-x-3">
            <div className="space-y-6 w-full">
              <div className="anim-up flex flex-col">
                <label className="subheading mb-2 font-medium">
                  {t('full_name')} <span className="required">*</span>
                </label>
                <Input
                  placeholder={t('full_name')}
                  name="fullname"
                  type='text'
                  onChange={formik.handleChange}
                  value={formik.values.fullname}
                />
                {validateValue("lastname", "Last name is required")}
              </div>
            </div>
          </div >
        )
        }

        {
          enableTaxID || isCompany ? (
            <div className="space-y-6">
              <div className="anim-up flex flex-col">
                <label className={`subheading mb-2 font-medium ${isCompany ? '' : 'flex justify-between items-center'}`}>
                  {t('tax_id')} {isCompany ? <span className="text-error">*</span> : <X className="inline-block w-4 h-4 cursor-pointer rounded-full p-0.5 bg-primary text-white bounce-btn stroke-[3px] hover:bg-primary/90" onClick={() => setEnableTaxID(false)} />}
                </label>
                <Input
                  placeholder={isCompany ? "3-0000-9999-9" : "1-1009-99999-09-9"}
                  name="tax_id"
                  type='tel'
                  className={isCompany ? validateValue("tax_id") : ""}
                  onChange={(e) => handleChangeValue(e, "tax_id")}
                  value={formik.values.tax_id}
                />
                {isCompany ? validateValue("tax_id", "Company Tax ID is required") : ""}
              </div>
            </div>
          ) : (
            <Button variant='link' onClick={() => setEnableTaxID(true)} className='justify-start p-0 h-fit -mt-4 md:-mt-6 text-secondary font-medium text-xs underline'>{t('enter_tax_id_text')}</Button>
          )
        }

        <div className="space-y-6">
          <div className="anim-up flex flex-col">
            <label className="subheading mb-2 font-medium">
              {t('address')} <span className="text-error">*</span>
            </label>
            <Input
              placeholder={t('address')}
              name="address"
              type='text'
              className={validateValue("address")}
              onChange={(e) => handleChangeValue(e, "address")}
              value={formik.values.address}
            />
            {validateValue("address", "Address is required")}
          </div>
        </div>

        <div className="flex flex-col gap-x-3 md:flex-row gap-y-6">
          <div className="space-y-6 w-full">
            <div className="anim-up flex flex-col">
              <label className="subheading mb-2 font-medium">
                {t('subdistrict')} <span className="text-error">*</span>
              </label>
              <Input
                placeholder={t('subdistrict')}
                name="subdistrict"
                type='text'
                className={validateValue("subdistrict")}
                onChange={(e) => handleChangeValue(e, "subdistrict")}
                value={formik.values.subdistrict}
              />
              {validateValue("subdistrict", "Subdistrict is required")}
            </div>
          </div>
          <div className="space-y-6 w-full">
            <div className="anim-up flex flex-col">
              <label className="subheading mb-2 font-medium">
                {t('district')} <span className="text-error">*</span>
              </label>
              <Input
                placeholder={t('district')}
                name="district"
                type='text'
                className={validateValue("district")}
                onChange={(e) => handleChangeValue(e, "district")}
                value={formik.values.district}
              />
              {validateValue("district", "District is required")}
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-x-3 md:flex-row gap-y-6">
          <div className="space-y-6 w-full">
            <div className="anim-up flex flex-col">
              <label className="subheading mb-2 font-medium">
                {t('province')} <span className="text-error">*</span>
              </label>
              <Select name="province" value={formik.values.province}
                onValueChange={value => formik.setFieldValue('province', value)}>
                <SelectTrigger className="w-full">
                  <SelectValue>{formik.values.province}</SelectValue>
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup className="max-h-[400px]">
                    {(i18n.language == "en" ? provinces : provincesTh).map(province => <SelectItem value={province} key={province}>{province}</SelectItem>)}
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>
          </div>
          <div className="space-y-6 w-full">
            <div className="anim-up flex flex-col">
              <label className="subheading mb-2 font-medium">
                {t('postal_code')} <span className="text-error">*</span>
              </label>
              <Input
                placeholder={t('postal_code')}
                name="postal_code"
                type='text'
                className={validateValue("postal_code")}
                onChange={(e) => handleChangeValue(e, "postal_code")}
                value={formik.values.postal_code}
              />
              {validateValue("postal_code", "Postal code is required")}
            </div>
          </div>
        </div>

        <Button type='submit' className={`btn-with-icon ${onSettings ? 'w-fit' : 'w-full'}`} disabled={!checkIfFilledAll}>
          {saving ? <Loader2 className="h-4 w-4 animate-spin" /> : null}
          {saving ? t('updating') : submitText}
        </Button>
      </form > */}
    </form>
  );
}
