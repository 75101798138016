import { Separator } from "src/components/ui/separator";
import { Wallet } from "lucide-react";
import { Link } from "react-router-dom";
import { BillingAddressForm } from "src/components/billingAddressForm";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router"
import { useQuery } from "react-query";
import { site } from "../../client/api";
import { useUser } from "../../hooks/useUser";
import { useTranslation } from "react-i18next";
import DrawLine from "src/components/drawLine";

export default function PaymentForm({ handleTypeChangeInvoice, handleonChangeWHTInvoice }) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { id } = useParams()
  const { app } = useParams()

  const { user, team } = useUser();
  const { data: sites } = useQuery('sites', site.list, {
    enabled: !!user,
  });

  const onPaymentSubmit = async (values) => {
    try {
      //if(app == 'plan'){
      const invoice_id = await site.create_invoice(values, sites.site_list.find(site => site.name.includes(process.env.REACT_APP_SITE_DOMAIN))?.name, id);
      if (invoice_id) {
        navigate(`/checkout/${app}/${id}/${invoice_id}`)
      }
      // }
      // else{
      //   await site.create_app_subscription(app,sites.site_list.find(site => site.name.includes(process.env.REACT_APP_SITE_DOMAIN))?.name,id);
      //   navigate(`/checkout/${app}/${id}`)
      // }
    } catch (error) {
      // Handle error here
    }
  }

  const handleTypeChange = async (values) => {
    handleTypeChangeInvoice(values);
  }

  const handleonChangeWHT = async (values) => {
    handleonChangeWHTInvoice(values);
  }

  return (
    <section className="w-full md:h-screen p-5 md:p-[30px] lg:p-[60px] overflow-auto payment-form">
      <Separator className='mb-6 md:hidden' />
      <h2 className="secondary-heading">{t('billing_info')}</h2>
      <p className="main-desc">{t('billing_info_desc')}</p>

      <BillingAddressForm
        billingAddress={team}
        onSubmitForm={onPaymentSubmit}
        submitText={(<>
          <Wallet color='#FFF' viewBox='0 0 24 24' height='16' width='16' />
          {t('pro_privileges.continue')}
        </>)}
        onSettings={false}
        onTypeChange={handleTypeChange}
        onChangeWHT={handleonChangeWHT}
      />

      <p className="text-desc mt-6">{t("By clicking 'Continue' you agree to authorize payments pursuant to")} <Link className="text-[#006AFF]">{t("privacy_policy")}</Link></p>
    </section>
  )
}