import { Tabs, TabsContent, TabsList, TabsTrigger } from "src/components/ui/tabs"
import WorkspaceCard from "./WorkspaceCard"
import { workspaceList, workspaceTriggers } from "./workspaceLists"
import useWorkspaces from "src/hooks/useWorkspaces"
import AdditionalBtn from "./AdditionalBtn"
import ScrollArrows from "../ScrollArrows"
import { useNavigate } from "react-router-dom"
import { workspaceImages } from "../icon-menus/workspace-images"
import FeatureCard from "src/components/FeatureCard"
import guidedTour from "src/img/guided-tour.mp4"
import introducing from "src/img/introducing-items-apps.mp4"
import itemsAppsImg from "src/img/your-item.svg"

const WorkspaceTabs = ({ type }) => {

  const tabBtnClassName = "font-medium !rounded-lg !px-4 !py-2 text-sm !shadow-workspace bg-white leading-5 flex items-center gap-2 text-darkergray-300"
  const { bgGradient, tabsTitle, additionalBtn, website } = useWorkspaces(type)
  const navigate = useNavigate()

  const WorkspaceTrigger = () => {
    return (
      <>
        {workspaceTriggers.map(trigger => (
          <TabsTrigger onClick={() => navigate(`/dashboard/workspace/${trigger.type !== "workspace" ? trigger.type : ""}`)} key={trigger.title} value={trigger.type} className={tabBtnClassName}>
            <img src={trigger.icon} className={`h-4 w-4 ${trigger.icon === workspaceImages.mainIcon ? "rotate-180" : ""}`}/>
            {trigger.title}
          </TabsTrigger>
        ))}
      </>
    )
  }

  const WorkspaceTabsContent = () => {
    return (
      <>
        {workspaceTriggers.map(trigger => (
          <TabsContent value={trigger.type} key={trigger.type} className="relative">
            <div className="flex items-center gap-x-4 px-6 overflow-auto">
              <div className={`grid grid-cols-2 gap-x-4 w-full`}>
                {workspaceList.filter(data => data.type === (type === undefined ? trigger.type : type)).map(list => (
                  <WorkspaceCard imageUrl={list.imageUrl} title={list.title} desc={list.desc} className="rounded-xl flex bg-white overflow-hidden items-end h-[246px]" imagePadding={list.imagePadding}/>
                ))}
              </div>

              {additionalBtn !== null && <AdditionalBtn link={website} text={additionalBtn.text} outerBg={additionalBtn.outerBg} innerBg={additionalBtn.innerBg} img={additionalBtn.img} imagePadding={additionalBtn.imagePadding}/>}
            </div>
          </TabsContent>
        ))}
      </>
    )
  }

  return (
    <div>
      <Tabs className="flex flex-col gap-y-6" defaultValue="workspace">
        <TabsList className={`!bg-transparent !flex !gap-x-4 !text-black justify-center flex-nowrap h-auto`}>
          <WorkspaceTrigger />
        </TabsList>
        {type === undefined && (
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
            <div className="relative bottom-0">
              <FeatureCard hasFooter={false} videoSrc={guidedTour} isCover={true} loop={true}/>

              <div className="flex flex-col gap-[26px] items-center absolute text-white bottom-[60px] w-full">
                <h1 className="!font-sfpro text-[30px] font-medium leading-7 tracking-[-1.5px]">Guided Tour</h1>
                <h1 className="recoleta text-[72px] leading-7 tracking-[-2.4px]" style={{textShadow: "0 4px 9.6px #00000040"}}>WorkSpace</h1>
              </div>
            </div>

            <div className="relative bottom-0">
              <FeatureCard hasFooter={false} videoSrc={introducing} isCover={true} loop={true}/>

              <div className="flex flex-col gap-[26px] justify-between items-center absolute text-white bottom-0 my-[30px] w-full h-[calc(100%_-_60px)]">
                <h1 className="!font-sfpro text-[30px] font-medium leading-7 tracking-[-1.5px]">Guided Tour</h1>
                <h1 className="recoleta text-[47px] leading-7 tracking-[-1.5px]" style={{textShadow: "0 4px 9.6px #00000040"}}>Getting started</h1>
              </div>
            </div>
          </div>
        )}

        <div className="rounded-3xl py-8 overflow-hidden bg-lightgray-100"> {/* style={{background: bgGradient}} */}
          <h1 className="font-bold text-xl text-darkergray-100 mb-6 px-6 leading-7">{tabsTitle}</h1>

          <WorkspaceTabsContent />
        </div>
      </Tabs>
    </div>
  )
}

export default WorkspaceTabs