import { ChevronLeft } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { Dialog, DialogContent } from "../ui/dialog";
import { cn } from "src/lib/utils";

export function MainModal({ 
  children, 
  mainClassName, 
  type,
  open,
  onClose
}){
  const initialClassName = "p-12 pt-8 overflow-auto"

  return (
    <Dialog open={open} onOpenChange={onClose}>
      {/* Create a top line of the modal when users are on the registration page */}
      {type === "register" ? (
        <div className="flex items-center justify-center h-screen">
          <div className="bg-[#00000059] rounded-xl h-[700px] max-w-[672px] absolute -translate-y-3 z-99 w-[90%]"></div>
        </div>
      ) : null}
      <DialogContent className={`main-modal ${type === "register" ? "!animate-none" : ""}`}>
        <main className={mainClassName ? cn(initialClassName, mainClassName) : initialClassName}>
          {children}
        </main>
      </DialogContent>
    </Dialog>
  )
}

export const MainModalHeader = ({
  title, 
  rightButton, 
  onClickRightButton,
  onClickBack
}) => {

  const navigate = useNavigate()

  return (
    <header className="main-modal-header sf-pro">
      <button className="flex items-center gap-1.5 text-link text-base !outline-none w-fit" onClick={onClickBack ? onClickBack : () => navigate(-1)}>
        <ChevronLeft />
        Back
      </button>
      <h2 className="font-medium text-lg text-center whitespace-pre">{title}</h2>
      {rightButton ? (
        <div className="flex justify-end mr-3">
          <button className="text-link text-base !outline-none w-fit" onClick={onClickRightButton}>
            {rightButton}
          </button>
        </div>
      ) : null}
    </header>
  )
}