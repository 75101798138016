import { useUser } from "../../hooks/useUser";
import { useMutation, useQuery } from "react-query";
import { site } from "../../client/api";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Input } from "../../components/ui/input"
import { user as user_api } from "src/client/api";
import { BillingAddressForm } from "../../components/billingAddressForm"
import { Loader2 } from "lucide-react";
import Loading from "src/components/ui/loading";
import { useTranslation } from "react-i18next";

// This can come from your database or API.
const defaultValues = {
  theme: "light",
}

export default function BillingPlan() {
  const { t } = useTranslation()
  const {team} = useUser();

  const onPaymentSubmit = async (values) => {

    try {
        const address = await site.update_user_address(values);
        console.log(address);
    } catch (error) {
      // Handle error here
    }


  }


  return (
    <>
      {team ? (
        <>
          <h1 className="secondary-heading">{t('billing_info')}</h1>
          <BillingAddressForm billingAddress={team} onSubmitForm={onPaymentSubmit}/>
        </>
      ) : (
        <Loading />
      )}
    </>
  )
}