import { Card, CardContent, CardFooter, CardHeader } from "src/components/ui/card";
import { Separator } from "src/components/ui/separator";
import { Button } from "src/components/ui/button";
import { BadgeCheck } from "lucide-react";
import Lottie from "lottie-react";
import receivedInfo from 'src/components/received-info-check.json'
import { useContext, useEffect, useState } from "react";
import { MemberContext } from "src/components/provider/memberProvider";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { site } from "../../client/api";
import { useUser } from "../../hooks/useUser";
import { useTranslation } from "react-i18next";

export default function CheckoutReceived() {
  const { t } = useTranslation()
  const { id } = useParams()
  const memberStatus = useContext(MemberContext)
  const navigate = useNavigate()
  const [subtotal, setSubtotal] = useState(0)
  const [discount, setDiscount] = useState(0)

  const { user, auth, logout } = useUser();
  const { data: sites } = useQuery('sites', site.list, {
    enabled: !!user,
  });

  const confirm = () => {
    navigate('/');
    memberStatus.change('pending')
  }

  const checkout_info = useQuery('checkout_info', () => site.get_web_plans(sites?.site_list.find(site => site.name.includes(process.env.REACT_APP_SITE_DOMAIN))?.name), { enabled: false });
  const plan_details = checkout_info?.data && checkout_info?.data.find(item => item.name === id);

  useEffect(() => {
    if (user && sites?.site_list.find(site => site.name.includes(process.env.REACT_APP_SITE_DOMAIN))?.name && !checkout_info.data) {
      checkout_info.refetch();
    }
  }, [user, sites, checkout_info]);

  if (!subtotal) {
    if (plan_details?.price_usd) {
      setSubtotal(plan_details?.price_usd)
    }
  }

  const vat = Math.floor(subtotal * 0);

  const total = () => {
    if (discount) {
      return ((subtotal - discount) + vat).toLocaleString()
    } else {
      return (subtotal + vat).toLocaleString()
    }
  };

  const invoiceDetailTable = [
    { label: t("payment.payment_notifications.invoice_detail"), value: "INV001" },
    { label: t("payment.payment_notifications.date"), value: "24-07-23" },
    { label: t("payment.payment_notifications.status"), value: t("payment.payment_notifications.in_progress") },
    { label: t("payment.payment_notifications.amount"), value: `฿${total()}` }
  ]

  return (
    <div className="page-section max-w-[580px] !mx-auto">
      <Card className='justify-center p-0 shadow-none border-0'>
        <CardHeader className='p-10'>
          <Lottie animationData={receivedInfo} loop={false} style={{ width: "128px", height: "128px", margin: "auto" }} />
          <h1 className="text-[36px] font-extrabold tracking-[-0.9px] leading-[40px] text-center text-primary">{t('payment.payment_successful.title')} 🎉</h1>
          <p className="mt-[12px!important] secondary-desc text-center">{t('payment.payment_successful.desc')}</p>
          <p className="main-desc mt-[24px!important] text-center">
            {t('payment.payment_successful.go_to_settings')}<a className="text-[#006AFF]" href='https://page.line.me/zaviago'>{t('contact_us').toLowerCase()}</a>
          </p>
        </CardHeader>

        <Separator />

        <CardContent className='p-10'>
          <h2 className="domain-heading mb-4">{t("payment.payment_notifications.invoice_detail")}</h2>
          <table className="w-full table-invoice-detail">
            <tbody>
              {invoiceDetailTable.map(detail => (
                <tr className="main-desc">
                  <td className="text-[#424242]">{detail.label}</td>
                  <td className="text-right text-darkergray">{detail.value}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </CardContent>

        <CardFooter className='pb-10 px-10'>
          <Button className='btn-with-icon w-full' onClick={confirm}>
            <BadgeCheck viewBox="0 0 24 24" width='16' height='16' />
            {t('back_to_workspace')}
          </Button>
        </CardFooter>
      </Card>
    </div>
  )
}