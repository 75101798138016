import { Fragment } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Dialog, Transition } from '@headlessui/react';
import { ChatBubbleBottomCenterTextIcon, XMarkIcon } from '@heroicons/react/24/solid';
import LoadingCheck from "../../components/loadingcheck";
import { SignupProvider } from '../../hooks/useSignup';
import { useFormik } from 'formik';
import StepMaintainer from '../../components/StepMaintainer';
import { useMutation } from 'react-query';
import { partial } from '../../client/api';
import { otpSchema, phoneSchema } from './validations/phoneVerificationSchema';
import Spacer from '../../components/spacer';
import { useState } from 'react';
import { Input } from '../../components/ui/input';
import { Button } from '../../components/ui/button';
import { useTranslation } from 'react-i18next';
import LoadingIcon from 'src/components/icon-menus/LoadingIcon';
import { MainModal, MainModalHeader } from 'src/components/modals/MainModal';
import { OTPInput } from 'input-otp'
import { cn } from 'src/lib/utils';
import { Icons } from 'src/components/ui/icons';
import { useTimer } from 'src/hooks/useTimer';

export default function Partial() {
    const location = useLocation();
    return (
        <SignupProvider state={location.state}>
            <Outlet />
        </SignupProvider>
    )
}

export const PhoneVerification = () => {
    const { key } = useParams();
    return (
        <MainModal open={true} type="register">
            <div className='flex justify-center w-full mb-8'>
                <div className='rounded-[42px] shadow-faceid'>
                    <Icons.faceID />
                </div>
            </div>
            <StepMaintainer key={key}>
                {/* <VerifyOTP /> */}
                <GetPhoneNumber />
                <VerifyOTP />
            </StepMaintainer>
        </MainModal>
    )
}

const GetPhoneNumber = ({
    next,
    initialValues = {
        countryCode: '66',
        phoneNumber: '',
    },
    validationSchema,
    onSubmit,
}) => {
    const { t } = useTranslation()
    const { key } = useParams();

    const { mutate, isLoading, isSuccess } = useMutation((data) => partial.requestOtp(key, `${data.countryCode}${data.phoneNumber}`), {
        onSuccess: next,
    })

    const formik = useFormik({
        initialValues: initialValues,
        validateOnChange: true,
        validationSchema: validationSchema ?? phoneSchema,
        onSubmit: mutate ?? onSubmit
    })

    return (
        <>
            <MainModalHeader title="Register" />
            <form className="regis-container" onSubmit={formik.handleSubmit}>
                <div className='flex flex-col items-center gap-y-4'>
                    <h2 className="primary-heading text-darkergray-500">{false ? "Resend OTP" : "ยืนยันตัวตน"}</h2>
                    <p className="text-secondary-foreground">{t('phone_verify.enter_phone')}</p>
                </div>
                <Spacer size={30} />
                <div className="space-y-8">
                    <div className="anim-up">
                        <label htmlFor="otp" className="font-medium text-darkergray-600">
                            {t('phone_verify.enter_phone_label')}
                        </label>
                        <div className="relative rounded-md border border-lightgray-700 my-1.5">
                            <div className="absolute inset-y-0 left-0 flex items-center font-medium">
                                <label htmlFor="country" className="sr-only">
                                    Country
                                </label>
                                <select
                                    className="h-full rounded-l-md border-r border-lightgray-700 bg-transparent py-0 pl-3 pr-1 text-gray-500 outline-none"
                                    name="countryCode"
                                    value={formik.values.countryCode}
                                    onChange={formik.handleChange}
                                >
                                    <option value="66">THA (+66)</option>
                                    <option value="1">USA (+1)</option>
                                    <option value="23">GBR (+23)</option>
                                    <option value="49">DEU (+49)</option>
                                    <option value="49">SGP (+65)</option>
                                    <option value="91">JPN (+81)</option>
                                    <option value="86">CHN (+86)</option>
                                    <option value="91">IND (+91)</option>
                                    <option value="92">PAK (+92)</option>
                                </select>
                            </div>
                            <Input
                                ref={null}
                                placeholder="091-234-5678"
                                className="regis-input"
                                style={{ paddingLeft: "132px" }}
                                name="phoneNumber"
                                type='tel'
                                value={formik.values.phoneNumber}
                                onChange={formik.handleChange}
                            />
                        </div>

                        {(formik.errors.phoneNumber || formik.errors.countryCode) && (<p className="error">{formik.errors.phoneNumber}</p>)}
                    </div>

                    <div className="anim-up-delay">
                        <Button
                            type='submit'
                            className='regis-btn'
                            disabled={formik.isSubmitting || !formik.isValid}
                        >{t('phone_verify.get_otp')}</Button>
                    </div>
                </div>
                <Transition.Root show={isLoading} as={Fragment}>
                    <Dialog as="div" className="relative z-[1001] !text-base" onClose={() => { }}>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        <div className="fixed inset-0 z-10 overflow-y-auto">
                            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                >
                                    <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white p-8 shadow-xl transition-all w-full max-w-[400px] flex flex-col gap-y-4">
                                        <div className="moving-line" />
                                        <p className="tab-desc text-left font-bold mb-3 flex gap-x-2">
                                            <ChatBubbleBottomCenterTextIcon width='24' />
                                            {t('creating_status.requesting_otp')}
                                        </p>
                                        {isLoading ? (
                                            <div className="flex items-center justify-between">
                                                <div className="flex gap-x-2 items-center">
                                                    <LoadingIcon />
                                                    <div className="text-left">
                                                        <Dialog.Title as="h3" className="subheading small">
                                                            {t('creating_status.requesting_otp')}
                                                        </Dialog.Title>
                                                    </div>
                                                </div>
                                                <div>
                                                    <p className='tab-desc'>{t('in_progress')}</p>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="flex items-center justify-between">
                                                <div className="flex gap-x-2 items-center">
                                                    <LoadingCheck type='primary' height='20px' />
                                                    <div className="text-left">
                                                        <Dialog.Title as="h3" className="subheading small">
                                                            {t('creating_status.requested_otp')}
                                                        </Dialog.Title>
                                                    </div>
                                                </div>
                                                <div>
                                                    <p className='text-link'>{t('done')}</p>
                                                </div>
                                            </div>
                                        )}

                                        {isSuccess ? (
                                            <div className="flex items-center justify-between">
                                                <div className="flex gap-x-2 items-center">
                                                    <LoadingCheck type='primary' height='20px' />
                                                    <Dialog.Title as="h3" className="subheading small">
                                                        {t('creating_status.sent_otp')}
                                                    </Dialog.Title>
                                                </div>
                                                <div>
                                                    <p className='text-link'>{t('done')}</p>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="flex items-center justify-between">
                                                <div className="flex gap-x-2 items-center">
                                                    <LoadingIcon />
                                                    <Dialog.Title as="h3" className="subheading small">
                                                        {t('creating_status.sending_otp')}
                                                    </Dialog.Title>
                                                </div>
                                                <div>
                                                    <p className='tab-desc'>{t('in_progress')}</p>
                                                </div>
                                            </div>
                                        )}

                                        {isSuccess && (
                                            <div className="flex items-center justify-between">
                                                <div className="flex gap-x-2 items-center">
                                                    <LoadingCheck type='primary' height='20px' />
                                                    <Dialog.Title as="h3" className="subheading small">
                                                        {t('creating_status.sent_otp_to_phone')}
                                                    </Dialog.Title>
                                                </div>
                                                <div>
                                                    <p className='text-link'>{t('done')}</p>
                                                </div>
                                            </div>
                                        )}
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    </Dialog>
                </Transition.Root>
            </form>
        </>
    )
}

const VerifyOTP = ({
    prev,
    initialValues = {
        otp: '',
    },
    validationSchema,
    onSubmit,
}) => {
    const { t } = useTranslation()
    const [show, setShow] = useState(false);
    const [requestNewOTP, setRequestNewOTP] = useState(false)
    const { key } = useParams();
    const navigate = useNavigate();

    const [isFilledOTP, setIsFilledOTP] = useState(false)
    const { mutate, isError, isSuccess } = useMutation((data) => partial.verifyOtp(key, Object.values(data).join('')), {
        onSuccess: (res) => {
            setShow(true); setTimeout((() => {
                navigate(`/partial/${key}/personal-info`)
            }), 1000)
        },
        onError: (err) => { setShow(true) },
    })

    const formik = useFormik({
        initialValues: initialValues,
        validateOnChange: true,
        validationSchema: validationSchema ?? otpSchema,
        onSubmit: mutate ?? onSubmit
    })

    const { timer, restartTimer } = useTimer(30, () => setRequestNewOTP(true))

    const handleRequestOTP = () => {
        setRequestNewOTP(false);
        restartTimer()
    }

    function Slot(props) {
        return (
          <div
            className={cn(
              'relative w-16 h-20 text-[2rem]',
              'flex items-center justify-center',
              'transition-all duration-300',
              'border-border border-y border-r first:border-l first:rounded-l-md last:rounded-r-md',
              'group-hover:border-accent-foreground/20 group-focus-within:border-accent-foreground/20',
              'outline outline-0 outline-accent-foreground/20',
              { 'outline-4 outline-accent-foreground': props.isActive },
            )}
          >
            {props.char !== null && <div className={cn('text-3xl')}>{props.char}</div>}
            {props.hasFakeCaret && <FakeCaret />}
          </div>
        )
      }

      function FakeCaret() {
        return (
          <div className="absolute pointer-events-none inset-0 flex items-center justify-center animate-caret-blink">
            <div className="w-px h-8 bg-white" />
          </div>
        )
      }

      function FakeDash() {
        return (
          <div className="flex w-12 justify-center items-center">
            <div className="w-4 h-1 rounded-full bg-border" />
          </div>
        )
      }

    return (
        <>
            <MainModalHeader title="Register" onClickBack={prev}/>
            <form className="regis-container text-center" onSubmit={formik.handleSubmit}>
                <div className='flex flex-col gap-y-4'>
                    <h2 className="primary-heading text-darkergray-500">{t(isSuccess ? 'phone_verify.confirmed_otp' : 'phone_verify.confirm_otp')}</h2>
                    <p className="text-secondary-foreground">{t('phone_verify.confirm_otp_desc')} {formik.values.phoneNumber}</p>
                </div>
                <Spacer size={30} />
                <div className="space-y-8">
                    <div className="flex flex-col items-center gap-4">
                        <OTPInput
                            onChange={(otp) => formik.setFieldValue('otp', otp)}
                            value={formik.values.otp}
                            autoFocus={true}
                            maxLength={6}
                            containerClassName="group flex items-center has-[:disabled]:opacity-30"
                            render={({ slots }) => {
                                {slots.map(slot => setIsFilledOTP(slot.char !== null ? true : false))}

                                return (
                                    <>
                                        <div className="flex">
                                            {slots.slice(0, 3).map((slot, idx) => (
                                            <Slot key={idx} {...slot} />
                                            ))}
                                        </div>
            
                                        <FakeDash />
                                    
                                        <div className="flex">
                                            {slots.slice(3).map((slot, idx) => (
                                            <Slot key={idx} {...slot} />
                                            ))}
                                        </div>
                                    </>
                                )
                            }}
                        />
                        {!requestNewOTP ? (
                            <p className="text-secondary mt-1 border px-5 py-2 rounded-full">{timer}</p> /* {timer} Will use the 'timer' as a dynamic timer, but due to the animation issue of the OTP input, I would statically set the timer to 00:30*/
                        ) : (
                            <p className="text-secondary mt-1">{t("Didn't receive SMS OTP?")} • <a className='text-darkblue cursor-pointer' onClick={handleRequestOTP}>{t('phone_verify.resend_otp')}</a></p>
                        )}
                    </div>

                    <div className="anim-up-delay">
                        <Button
                            type='submit'
                            className={isFilledOTP ? 'regis-btn' : 'hidden'}
                            // disabled={!formik.isValid}
                        >{t('phone_verify.confirm_otp')}</Button>
                        {/* <p className="text-secondary mt-3">{t('phone_verify.enter_wrong_number')} <span onClick={prev} className='text-[#0788F5] cursor-pointer'>{t('phone_verify.change_phone')}</span></p> */}
                        {/* {isError && (<p className="error">{t('phone_verify.incorrect_otp')}</p>)} */}
                    </div>
                </div>

                <Transition.Root show={show} as={Fragment}>
                    <Dialog as="div" className="relative z-[1001]" onClose={() => setShow(false)}>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        <div className="fixed inset-0 z-10 overflow-y-auto">
                            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                >
                                    {isSuccess ? (
                                        <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white p-8 shadow-xl transition-all w-full max-w-[400px] flex flex-col gap-y-4">
                                            <div className="moving-line" />
                                            <LoadingCheck type='success' />
                                            <p className="tab-desc justify-center font-bold flex">
                                                {t('phone_verify.confirmed_otp')}
                                            </p>
                                        </Dialog.Panel>
                                    ) : (
                                        <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white p-8 shadow-xl transition-all w-full max-w-[400px] flex flex-col gap-y-4">
                                            <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100">
                                                <XMarkIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                                            </div>
                                            <p className="tab-desc justify-center font-bold flex">
                                                {t('phone_verify.incorrect_otp_two')}
                                            </p>
                                        </Dialog.Panel>
                                    )}
                                </Transition.Child>
                            </div>
                        </div>
                    </Dialog>
                </Transition.Root>
            </form>
        </>
    )
}