import React, { useState, useEffect, useContext, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useUser } from "../../hooks/useUser";
import { Settings, Users, Zap, UserCircle, LayoutGrid, ClipboardList, Package, Group, Baseline, Clipboard, CheckCircle2, UserSquare, Mailbox, Milestone, PackagePlus, ClipboardPaste, ChevronsRight, Hotel, Unplug, Rocket } from "lucide-react";
import { Button } from "../ui/button";
import { LightningBoltIcon } from "@radix-ui/react-icons";
import { useMutation, useQuery } from "react-query";
import { site } from "../../client/api";
import { SearchItem } from "../topbar/searchBar";
import SidebarUpgrade from "./sidebarUpgrade";
import { useTranslation } from "react-i18next";
import { workspaceImages, dashboardActivities } from "../icon-menus/workspace-images";
import { Separator } from "../ui/separator";
import { Progress } from "../ui/progress";
import { getToken, setLoginSource } from "../../utils/helper";
import { Icons } from "../ui/icons";
import { ACCOUNT_MENUS } from "../topbar/components/menus";
import { AccountModal } from "../topbar/components/AccountModalMenus";
import useTopbarMenu from "src/hooks/useTopbarMenu";
import { usePostHog } from "posthog-js/react";


// import TeamModal from "../components/switchTeamModal";

export default function Sidebar({ loadingLogo, isSidebarOpen, setIsSidebarOpen }) {
  const { t, i18n } = useTranslation()
  const [active, setActive] = useState('');
  const location = useLocation();
  const { user } = useUser();
  const [upgraded, setUpgraded] = useState(false)
  const [isMobile, setIsMobile] = useState()
  const sso_token = getToken();

  const posthog = usePostHog();
  const benchApps = useQuery('benchApps', () => site.appslist(sites.site_list.find(site => site.name.includes(process.env.REACT_APP_SITE_DOMAIN))?.name), { enabled: false });
  const installedApps = useQuery('installed_apps', () => site.installed_apps(sites.site_list.find(site => site.name.includes(process.env.REACT_APP_SITE_DOMAIN))?.name), { enabled: false });
  const appslists = benchApps.data || [];

  const navigate = useNavigate();
  const resize = () => window.innerWidth > 1024 ? setIsMobile(false) : setIsMobile(true)

  const { handleBack, isModalOpen, setIsModalOpen, handleAccountModal, key, renderComponent } = useTopbarMenu()

  const { data: sites } = useQuery('sites', site.list, { enabled: false });

  const { mutate: loginAsAdmin } = useMutation('loginAsAdmin', ({ name, reason }) => site.login(name, reason), {
    onSuccess: (res) => {
      const { sid, site } = res.data.message;
      if (sid && site) {
        window.open(`https://${site}/app/home?sid=${sid}&posthog_session_id=${posthog.get_session_id()}`, '_blank');
      }
    }
  });

  const navigation = [
    {
      icon: <Icons.home />,
      onClick: () => navigate('/dashboard/app'),
      current: active === "/dashboard/app"
    },
    {
      icon: <Icons.users />,
      onClick: () => handleAccountModal("Manage Team Members")
    },
    {
      icon: <Icons.settings />,
      onClick: () => handleAccountModal()
    },
    {
      icon: <Icons.chat />,
      onClick: () => window.location.href = "https://huly.zaviago.com/"
    }
  ]

  const settingsMenus = [
    {
      name: 'Inventory', icon: <LightningBoltIcon />, submenus: [
        { title: 'Items', icon: <Package className="w-4 h-4 stroke-[1.5] text-darkergray" /> },
        { title: 'Item Group', icon: <Group className="w-4 h-4 stroke-[1.5] text-darkergray" /> },
        { title: 'Brand', icon: <Baseline className="w-4 h-4 stroke-[1.5] text-darkergray" /> }
      ]
    },
    {
      name: 'Orders', icon: <ClipboardList className="w-4 h-4 stroke-[1.5] text-darkergray" />, submenus: [
        { title: 'Sales Invoice', icon: <Clipboard className="w-4 h-4 stroke-[1.5] text-darkergray" /> },
        { title: 'Payment Entry', icon: <CheckCircle2 className="w-4 h-4 stroke-[1.5] text-darkergray" /> }
      ]
    },
    {
      name: 'Customers', icon: <UserCircle className="w-4 h-4 stroke-[1.5] text-darkergray" />, submenus: [
        { title: 'Customer', icon: <UserSquare className="w-4 h-4 stroke-[1.5] text-darkergray" /> },
        { title: 'Customers Group', icon: <Users className="w-4 h-4 stroke-[1.5] text-darkergray" /> },
        { title: 'Contact', icon: <Mailbox className="w-4 h-4 stroke-[1.5] text-darkergray" /> },
        { title: 'Address', icon: <Milestone className="w-4 h-4 stroke-[1.5] text-darkergray" /> }
      ]
    },
    {
      name: 'Stock', icon: <LayoutGrid className="w-4 h-4 stroke-[1.5] text-darkergray" />, submenus: [
        { title: 'Stock Entry', icon: <PackagePlus className="w-4 h-4 stroke-[1.5] text-darkergray" /> },
        { title: 'Delivery Note', icon: <ClipboardPaste className="w-4 h-4 stroke-[1.5] text-darkergray" /> }
      ]
    },
  ]

  const yourSites = [
    { name: t('menus.integration'), icon: <Zap className="w-4 h-4 stroke-[1.5] text-darkergray" />, id: 'integration', href: '/integration/manage-apps', current: active === '/integration/manage-apps' || active === '/integration/upgrade-apps' ? true : false },
    { name: 'App Store', icon: <Unplug className="w-4 h-4 stroke-[1.5] text-darkergray" />, id: 'app-store', href: '/integration/appstore', current: active === '/integration/appstore' ? true : false },
  ]

  const workspaceApp = [
    { name: 'Workspace', icon: workspaceImages.customerDataSystem, onClick: () => navigate('/dashboard/workspace') },
    { name: 'TeamInbox', icon: workspaceImages.pos, onClick: () => navigate('/dashboard/workspace/inbox') },
    { name: 'WorkDay', icon: workspaceImages.projectManagement, onClick: () => navigate('/dashboard/workspace/workday') },
    { name: 'LineCRM', icon: workspaceImages.linecrm, onClick: () => navigate('/dashboard/workspace/line-crm') },
    { name: 'SalesTeam', icon: workspaceImages.salesteam, onClick: () => window.open('/coming-soon', '_blank') },
    { name: 'Zaviago HR', icon: workspaceImages.hrspace, onClick: () => window.open('https://apps.hosting.zaviago.com/app/hr', '_self') },
  ]

  const workspaceAppTwo = [
    { name: 'Data Insight', icon: workspaceImages.blogAndNews, onClick: () => navigate('/dashboard/workspace/data-insight') },
    { name: 'Drive', icon: workspaceImages.manageWebsite, onClick: () => navigate('/dashboard/workspace/drive') },
  ]

  const workspaceAppThree = [
    { name: 'Blogs', icon: workspaceImages.blogAndNews, onClick: () => window.open('https://ghost.zaviago.com/ghost/#/dashboard', '_self') },
    {
      name: 'Builder',
      icon: workspaceImages.manageWebsite,
      onClick: () => {
        const team = localStorage.getItem('current_team');
        let url = `https://site-builder.zaviago.com/login?token=${sso_token}`;

        if (team !== null && team !== undefined) {
          url += `&team=${team}`;
        }
        window.location.href = url;
      }
    },
    { name: 'Whiteboard', icon: workspaceImages.whiteboard, onClick: () => window.open('https://affine.zaviago.com/', '_self') },
  ]

  useEffect(() => {
    setActive(location.pathname);
    resize()

    window.addEventListener("resize", resize)
  }, [isMobile])

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  const [query, setQuery] = useState('')
  const [sellingMenus, setSellingMenus] = useState(false)
  const [activeSubmenus, setActiveSubmenus] = useState([]);

  const handleSubMenuClick = (index) => {
    setActiveSubmenus((prev) => {
      const newActiveSubmenus = [...prev];
      newActiveSubmenus[index] = !newActiveSubmenus[index];
      return newActiveSubmenus;
    });
  };

  const { data: loadAdmin, refetch } = useQuery('loadAdmin', () => site.login(sites?.site_list.find(site => site.name.includes(process.env.REACT_APP_SITE_DOMAIN))?.name, 'Admin'), {
    enabled: false,
    onSuccess: (res) => {
      //console.log(res);
    },
  });

  const loginNow = (page) => {
    var sid = loadAdmin?.data?.message.sid;
    var sitetoview = sites?.site_list.find(site => site.name.includes(process.env.REACT_APP_SITE_DOMAIN))?.name;
    setLoginSource(sitetoview);
    if (sid) {
      window.open(`https://${sitetoview}/app/${page}?sid=${sid}&posthog_session_id=${posthog.get_session_id()}`, '_blank');
    }
  }

  const squareGradient = {
    background: "linear-gradient(248.71deg, rgba(251, 152, 106, 0.16) 9.18%, rgba(251, 152, 106, 0.16) 32.04%, rgba(244, 99, 173, 0.16) 50.41%, rgba(34, 85, 207, 0.16) 90.82%)"
  }

  return (
    <>
      {/* <IconSidebar /> */}
      <div id="sidebar">
        <div className="flex flex-1 flex-col w-full">
          <nav className="flex justify-center flex-col gap-y-3" aria-label="Sidebar">
            <section className="flex flex-col gap-y-5 items-center pt-3">
              {navigation.map((item, index) => (
                <Button key={index} variant='ghost' onClick={item.onClick} className={`rounded-xl p-0 h-11 w-11 flex items-center ${item.current ? 'border border-[#EEEEEE]' : ''}`}>
                  {item.icon}
                </Button>
              ))}
              <AccountModal
                open={isModalOpen}
                onClose={setIsModalOpen}
                title={key}
                onClickBack={handleBack}
                component={renderComponent()}
              />
            </section>
          </nav>
          {/* {!upgraded && <SidebarUpgrade onClick={() => setIsSidebarOpen(false)} />} */}
        </div>
      </div>
    </>
  )
}