import { Table, TableBody, TableCell, TableRow } from "src/components/ui/table";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectGroup, SelectValue } from "src/components/ui/select";
import { Input } from "src/components/ui/input"
import { ChevronRight } from "@untitled-ui/icons-react/build/cjs";
import { cn } from "src/lib/utils";

const InputSection = ({ label, placeholder, defaultValue, type, value, name, onChange, options, onValueChange }) => {
    return (
      <TableRow>
        <TableCell className="w-1/2 py-1 px-0">
          <label className="text-base text-black">{label}</label>
        </TableCell>
        <TableCell className="w-1/2 py-1 px-0">
          {type !== "select" ? (
            <Input
              className="!outline-none !ring-0 !ring-offset-0 !text-base bg-transparent p-0 placeholder:text-lightgray-900/30 placeholder:font-light"
              placeholder={placeholder}
              name={name}
              type={type}
              onChange={onChange}
              defaultValue={defaultValue}
              value={value}
            />
          ) : (
            <Select name={name} value={value}
              onValueChange={onValueChange}
            >
              <SelectTrigger className={cn("w-full p-0 bg-transparent !outline-none pr-4 !ring-0 !ring-offset-0 !text-base justify-end gap-2", "text-black", {"text-lightgray-900/60": defaultValue == placeholder})}>
                <SelectValue placeholder={placeholder}>{value}</SelectValue>

                <ChevronRight className="absolute text-lightgray-900/30 right-2 bg-lightgray-100 z-5"/>
              </SelectTrigger>
              <SelectContent className="!z-99">
                <SelectGroup className="max-h-[500px] relative">
                  {options.map(option => option)}
                </SelectGroup>
              </SelectContent>
            </Select>
          )}
        </TableCell>
      </TableRow>
    )
  }

export default InputSection