import { Button } from "src/components/ui/button"
import { Icons } from "src/components/ui/icons"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "src/components/ui/tabs"
import ZaviagoCash from "src/components/icon-menus/upgrade-details/ZaviagoCash"
import Promptpay from "src/components/icon-menus/upgrade-details/Promptpay"
import Krungthai from "src/components/icon-menus/upgrade-details/Krungthai"
import { TableBody, Table, TableCell, TableRow } from "src/components/ui/table"
import { ChevronRight } from "@untitled-ui/icons-react/build/cjs"

const Checkout = () => {

  const payment_selections = [
    { title: 'Zaviago Cash', icon: <ZaviagoCash className="mt-2"/>},
    { title: 'QR code Payment', icon: <Promptpay className="ml-3"/>},
    { title: 'Bank Transfer', icon: <Krungthai className="ml-3"/>}
  ]

    return (
      <section className="flex flex-col items-center">
        <div className="w-full">
          <div className="flex flex-col">
            <Icons.storefrontIcon />
            <p className="text-xs text-lightgray-900/60">Powered by Zaviago Storefront.</p>
          </div>

          <Tabs defaultValue="1 year">
            <TabsList className="grid w-full grid-cols-2 mt-5 h-auto">
              <TabsTrigger value="1 year">1 year (30% Discount)</TabsTrigger>
              <TabsTrigger value="3 months">3 months</TabsTrigger>
            </TabsList>

            <TabsContent value="1 year">
              <Table className="relative left-20">
                <TableBody>
                  {payment_selections.map(menu => (
                    <TableRow className="hover:bg-transparent">
                      <TableCell className="flex items-center -ml-20 px-0 py-4 gap-4 relative">
                        <div className='w-16'>{menu.icon}</div>
                        <div className="flex items-center">
                          <h3 className="font-normal text-base">{menu.title}</h3>
                          <ChevronRight className="absolute right-24 text-[#AFB0B4]"/>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <p className="text-lightgray-900/60 text-sm ml-20">Zaviago Cash Available <span className="bg-[#F2F2F7] rounded-sm px-1">$147.17</span></p>


              <div className="mt-7 mb-10 flex items-center justify-between pr-4">
                <div>
                  <p className="text-lightgray-900/60 text-sm">Pro Plan (1 Year)</p>
                  <h3 className="text-[28px] font-medium">THB 9,000.00</h3>
                </div>

                <ChevronRight className="text-[#AFB0B4]"/>
              </div>
            </TabsContent>
            <TabsContent value="3 months">
              <Table className="relative left-20">
                <TableBody>
                  {payment_selections.map(menu => (
                    <TableRow className="hover:bg-transparent">
                      <TableCell className="flex items-center -ml-20 px-0 py-4 gap-4 relative">
                        <div className='w-16'>{menu.icon}</div>
                        <div className="flex items-center">
                          <h3 className="font-normal text-base">{menu.title}</h3>
                          <ChevronRight className="absolute right-24 text-[#AFB0B4]"/>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <p className="text-lightgray-900/60 text-sm ml-20">Zaviago Cash Available <span className="bg-[#F2F2F7] rounded-sm px-1">$147.17</span></p>

              <div className="mt-7 mb-10 flex items-center justify-between pr-4">
                <div>
                  <p className="text-lightgray-900/60 text-sm">Pro Plan (3 Months)</p>
                  <h3 className="text-[28px] font-medium">THB 9,000.00</h3>
                </div>

                <ChevronRight className="text-[#AFB0B4]"/>
              </div>
            </TabsContent>
          </Tabs>
        </div>

        <Button className="regis-btn">Confirm</Button>
      </section>
    )
}

export default Checkout