import { useQuery } from "react-query";
import { workspaceActivities, workspaceImages } from "src/components/icon-menus/workspace-images"
import { site } from "../client/api";

const useWorkspaces = (type) => {

  const { data: sites } = useQuery('sites', site.list, { enabled: false });
  const checkIfSites = sites ? sites?.site_list.find(site => site.name.includes(process.env.REACT_APP_SITE_DOMAIN))?.name : ""

  const heading = type === "builder" ? {
    title: "Builder",
    icon: workspaceImages.manageWebsite,
    desc: null
  } : type === "drive" ? {
    title: "Drive",
    icon: workspaceImages.manageWebsite,
    desc: null
  } : type === "data-insight" ? {
    title: "Data Insight",
    icon: workspaceImages.blogAndNews,
    desc: null
  } : type === "line-crm" ? {
    title: "Point & Reward",
    icon: workspaceImages.linecrm,
    desc: null
  } : type === "workday" ? {
    title: "WorkDay",
    icon: workspaceImages.projectManagement,
    desc: "Get everyone working in a single platform designed to manage any type of work."
  } : type === "inbox" ? {
    title: "Inbox",
    icon: workspaceImages.pos,
    desc: "The complete customer service solution with shared inbox, help center, and live chat software."
  } : type === undefined ? {
    title: "WorkSpace",
    icon: workspaceImages.manageBusiness,
    desc: null
  } : {
    title: null,
    icon: null,
    desc: null
  }

  const website =
    type === "inbox" ? "chat.zaviago.com"
      : type === "workday" ? "workday.zaviago.com"
        : type === "drive" ? `${checkIfSites}/drive`
          : checkIfSites

  const additionalBtn = type === "builder" ? {
    outerBg: "bg-[#FFD4ED]",
    innerBg: "bg-[#FFADD84A]",
    text: "ไปที่ Builder",
    img: workspaceActivities.goToBuilder,
  } : type === "drive" ? {
    outerBg: "bg-[#A4C7FF]",
    innerBg: "bg-[#3083FF4A]",
    text: "ไปที่ Drive",
    img: workspaceActivities.goToDrive,
    imagePadding: "12px 0 0"
  } : type === "line-crm" ? {
    outerBg: "bg-[#56C741]",
    innerBg: "bg-[#00642D4A]",
    text: "ระบบอื่นๆ",
    img: workspaceActivities.otherSystemsLinecrm
  } : type === "workday" ? {
    outerBg: "bg-[#E8CCF5]",
    innerBg: "bg-[#B67CFF4A]",
    text: "ระบบอื่นๆ",
    img: workspaceActivities.otherSystemsWorkday
  } : type === "inbox" ? {
    outerBg: "bg-[#47A7F6]",
    innerBg: "bg-[#0067CD4A]",
    text: "ไปที่แดชบอร์ด",
    img: workspaceActivities.goToDashboard,
    imagePadding: "0 0 0 12px"
  } : type === undefined ? {
    outerBg: "bg-[#6600E9]",
    innerBg: "bg-[#6600E94A]",
    text: "ไปที่ WorkSpace",
    img: workspaceActivities.goToWorkspace,
    imagePadding: "32px 0 0"
  } : null

  const tabsTitle = type === "inbox" ?
    "อินบ็อกซ์ต่างๆ"
    : "วันนี้คุณต้องการทำอะไร?"

  const bgGradient = type === "builder" ?
    "linear-gradient(180deg, #FFFFFF -72.08%, #FFFFFF -72.04%, #FFE3F4 224.77%)"
    : type === "drive" ?
      "linear-gradient(180deg, #FFFFFF -72.08%, #FFFFFF -72.04%, #AACCFF 224.77%)"
      : type === "data-insight" ?
        "linear-gradient(180deg, #FFFFFF -30.11%, #B6DCFF 238.76%)"
        : type === "line-crm" ?
          "linear-gradient(180deg, #FFFFFF -30.11%, #CCEFDC 238.76%)"
          : type === "workday" ?
            "linear-gradient(180deg, #FFFFFF -72.08%, #E8CCF5 238.76%)"
            : type === "inbox" ?
              "linear-gradient(180deg, #FFFFFF -72.08%, #C5E2FF 238.76%)"
              : "linear-gradient(180deg, #FFFFFF -72.08%, #FFFFFF -72.04%, #DDD5FF 224.77%)"

  return { heading, bgGradient, tabsTitle, additionalBtn, website }
}

export default useWorkspaces