import FeatureCard from "src/components/FeatureCard"
import { Button } from "src/components/ui/button"
import guidedTour from "src/img/guided-tour.mp4"
import introducing from "src/img/introducing-items-apps.mp4"
import itemsAppsImg from "src/img/your-item.svg"

export default function DashboardVideo(){
  return (
    <section className="flex flex-col gap-y-5">
      <h2 className="text-darkergray-100 text-xl font-bold">แนะนำการใช้งานระบบ</h2>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        <div className="relative bottom-0">
          <FeatureCard hasFooter={false} videoSrc={guidedTour} isCover={true} loop={true}/>
          <div className="flex flex-col gap-[26px] items-center absolute text-white bottom-[60px] w-full">
            <h1 className="!font-sfpro text-[30px] font-medium leading-7 tracking-[-1.5px]">Guided Tour</h1>
            <h1 className="recoleta text-[72px] leading-7 tracking-[-2.4px]" style={{textShadow: "0 4px 9.6px #00000040"}}>WorkSpace</h1>
          </div>
        </div>
        <div className="relative bottom-0">
          <FeatureCard hasFooter={false} videoSrc={introducing} isCover={true} loop={true}/>
          <div className="flex flex-col items-center absolute text-white bottom-0 w-full h-full justify-center">
            <h1 
              className="!font-sfpro text-[30px] font-medium tracking-[-1.6px] leading-7 sfpro"
              style={{textShadow: "0 4px 6.2px #00000052"}}
            >
              Introducing
            </h1>
            <h1 
              className="!font-sfpro flex items-center gap-[18px] text-[72px] tracking-[-2.4px] leading-7 font-medium"
              style={{textShadow: "0 4px 6.2px #00000052"}}
            >
              <img src={itemsAppsImg} className="h-[76px] w-[76px]"/>
              Items apps.
            </h1>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-8">
        <Button variant="ghost" className="bg-lightgray-100 !text-link text-base py-5">Team invite</Button>
        <Button variant="ghost" className="bg-lightgray-100 !text-link text-base py-5">Upgrade to Pro</Button>
      </div>
    </section>
  )
}