import { Avatar, AvatarFallback, AvatarImage } from "../ui/avatar"
import { site } from "../../client/api";
import { useMutation, useQuery } from "react-query";
import { useUser } from '../../hooks/useUser'
import { useTranslation } from "react-i18next";
import { AccountModal } from "./components/AccountModalMenus";
import useTopbarMenu from "src/hooks/useTopbarMenu";

export default function AvatarMenu(){
  const { t, i18n } = useTranslation()
  const { user, auth, logout } = useUser();
  const { data: sites } = useQuery('sites', site.list, {
    enabled: false
  });

  const { isModalOpen, setIsModalOpen, key, renderComponent, handleAccountModal, handleBack } = useTopbarMenu()

  return (
    <>
      <Avatar className='w-8 h-8 text-sm cursor-pointer' onClick={() => handleAccountModal()}>
        <AvatarImage src="" />
        <AvatarFallback>{user?.first_name[0]}</AvatarFallback>
      </Avatar>

      <AccountModal 
        open={isModalOpen}
        onClose={setIsModalOpen}
        title={key}
        onClickBack={handleBack} 
        component={renderComponent()}
      />
    </>
  )
}