import { AccountModalMenus } from "src/components/topbar/components/AccountModalMenus";
import { ACCOUNT_MENUS } from "src/components/topbar/components/menus";
import { useUser } from "./useUser";
import { useTranslation } from "react-i18next";
import { Table, TableBody, TableCell, TableRow } from "src/components/ui/table";
import { useEffect, useState } from "react";
import { Avatar, AvatarFallback, AvatarImage } from "src/components/ui/avatar"
import { Check } from "@untitled-ui/icons-react/build/cjs";

const useTopbarMenu = () => {
  const { t, i18n } = useTranslation()
  const { user, auth, logout } = useUser();
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [currentMenu, setCurrentMenu] = useState({
    title: "",
    key: "",
    component: null,
    group: ""
  })

  const { key } = currentMenu
  const [history, setHistory] = useState([])
  const filterMenu = (group) => {
    return ACCOUNT_MENUS.filter(menu => menu.group === group)
  }
  const removeDuplicates = (arr) => {
    const seen = new Set();
    return arr.filter((item) => {
      const duplicate = seen.has(item.key);
      seen.add(item.key);
      return !duplicate;
    });
  };
  const handleMenuChange = (menu) => {
    setHistory((prevHistory) => {
      const newHistory = [...prevHistory, menu];
      return removeDuplicates(newHistory);
    });
    setCurrentMenu(menu);
  };
  const handleChangeComp = (title = "My Account") => {
    return ACCOUNT_MENUS.filter(menu => menu.title === title)[0]
  }
  const handleAccountModal = (link) => {
    !isModalOpen && setIsModalOpen(true)
    const targetMenu = link ? handleChangeComp(link) : defaultMenu;
    handleMenuChange(targetMenu);
  }
  const DefaultComp = () => (
    <section className="flex flex-col gap-10">
      <div className="bg-lightgray-100 rounded-lg px-4 py-3 flex items-center gap-3 cursor-pointer" onClick={() => handleAccountModal("My Account")}>
        <Avatar className='w-13 h-13'>
          <AvatarImage src="" />
          <AvatarFallback>{user?.first_name[0]}</AvatarFallback>
        </Avatar>
        <div className="leading-5">
          <h3 className="text-black text-base">{`${user?.first_name} ${user?.last_name}` || "Name Surname"}</h3>
          <p className="text-lightgray-900/60 text-sm">{user?.email || 'test@mail.com'}</p>
        </div>
      </div>
      <AccountModalMenus title="BILLING & PLAN" menus={filterMenu("billing")} onSelect={(val) => handleMenuChange(val)} className="pl-4"/>
      <AccountModalMenus title="TEAMS MEMBER" menus={filterMenu("team_members")} onSelect={(val) => handleMenuChange(val)} className="pl-4"/>
      <AccountModalMenus title="LANGUAGE" className="pl-4">
        <Table className="relative left-10">
          <TableBody>
            <LanguageChoice language="th" type="Thai"/>
            <LanguageChoice language="en" type="English"/>
          </TableBody>
        </Table>
      </AccountModalMenus>
      <AccountModalMenus menus={filterMenu("logout")} className="pl-4" onSelect={() => logout()}/>
    </section>
  )

  useEffect(() => {
    if (localStorage.lang === undefined || null){
      localStorage.setItem('lang', i18n.language)
    }
    if (!isModalOpen){
      setHistory([])
    }
  }, [localStorage.lang, isModalOpen])

  const LanguageChoice = ({ language, type }) => {
    const handleChangeLang = (lang) => {
      if (localStorage.lang !== null){
        i18n.changeLanguage(lang)
        localStorage.setItem('lang', lang)
      }
    }
    return (
      <TableRow className="cursor-pointer">
        <TableCell className="px-0 py-3 -ml-10 flex items-center w-full font-normal text-black text-base gap-4" onClick={() => handleChangeLang(language)}>
          {localStorage.lang === language ? (
            <span className="flex w-6 h-6 bg-link rounded-full items-center justify-center">
              <Check color="white" className="w-4 h-4" viewBox="0 0 24 24"/>
            </span>
          ) : (
            <span className="flex w-6 h-6 rounded-full border-2 border-lightgray-950"></span>
          )}
          <p>{type}</p>
        </TableCell>
      </TableRow>
    )
  }
  const defaultMenu = {
    title: "Account",
    key: "Account",
    component: DefaultComp,
    group: "default"
  }
  const renderComponent = () => {
    if (!currentMenu?.component) return defaultMenu.component;
    const ActiveComponent = currentMenu?.component
    return <ActiveComponent setCurrentMenu={handleAccountModal}/>
  }

  const handleBack = () => {
    setHistory((prevHistory) => {
      if (prevHistory.length > 1) {
        const updatedHistory = prevHistory.slice(0, -1);
        const previousMenu = updatedHistory[updatedHistory.length - 1];
        setCurrentMenu(previousMenu);
        return updatedHistory;
      } else {
        setIsModalOpen(false);
        return prevHistory;
      }
    });
  };

  // console.log(history)

  return { key, defaultMenu, handleAccountModal, isModalOpen, setIsModalOpen, renderComponent, handleBack }
}

export default useTopbarMenu