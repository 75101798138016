import { Card } from "src/components/ui/card"
import { Button } from "src/components/ui/button";
import gettingStartedImg from "src/img/getting-started.png"
import { Link } from "react-router-dom";
import { Backpack, Building05, ClipboardPlus, VideoRecorder } from "@untitled-ui/icons-react/build/cjs";
import { useState } from "react";

export default function SetupBusiness({OpenPage}){
  const [currentStep, setCurrentStep] = useState(0)
  const iconClassName = "w-6 h-6 stroke-[1.5]"
  const setupMenus = [
    { title:"เพิ่มข้อมูลธุรกิจของคุณ", icon:<Building05 className={`${iconClassName} text-[#007AFF]`}/> , slug:"company/Default%20Company" },
    { title:"สร้างและอัปโหลดสินค้าชิ้นแรก", icon:<Backpack className={`${iconClassName} text-[#DD2590]`}/> , slug:"item/new-item-puwluaoqbm" },
    { title:"ทดลองเปิดคำสั่งซื้อ", icon:<ClipboardPlus className={`${iconClassName} text-[#00B14F]`}/> , slug:"sales-invoice" },
    { title:"ดูไกด์แนะนำฟีเจอร์ต่างๆ", icon:<VideoRecorder className={`${iconClassName} text-[#F98200]`}/> , slug:"" }
  ]
  return (
    <Card className='shadow-getting-started w-full flex flex-col lg:flex-row border border-tertiary overflow-hidden'>
      <section className="bg-[#F7F7F8E0] pl-10 pt-[34px] pb-12 pr-6 w-auto lg:w-1/3 flex flex-col gap-y-5">
        <div className="flex justify-between text-llg font-medium items-center leading-7">
          <h2 className="text-[#222222]">Getting Started {currentStep}/4</h2>
          <Link className="text-link" to="">See all</Link>
        </div>
        <div className="flex flex-col gap-3">
          {setupMenus.map(menu => (
            <Button variant="ghost" key={menu.title} className="h-12 px-3.5 rounded-xl bg-white flex gap-3 items-center justify-start" onClick={() => OpenPage(menu.slug)}>
              {menu.icon}
              <h2 className={`text-sm font-medium text-darkergray-200 leading-5`}>{menu.title}</h2>
            </Button>
          ))}
        </div>
      </section>
      <section className="w-auto lg:w-2/3 flex items-end justify-center">
        <img src={gettingStartedImg} className="w-[90%] max-w-[800px]"/>
      </section>
    </Card>
  )
}