import UpgradeProModal from "../modals/upgradeProModal"
import { site } from "../../client/api";
import { useUser } from "../../hooks/useUser";
import { useQuery } from "react-query";
import UpgradeLogo from "../icon-menus/UpgradeLogo";
import { useState } from "react";
import useTopbarMenu from "src/hooks/useTopbarMenu";
import { AccountModal } from "./components/AccountModalMenus";

const UpgradeProButton = () => {
  const { user } = useUser();
  const { data: sites } = useQuery('sites', site.list, {
    enabled: !!user,
  });

  const { data: siteOverview } = useQuery(['site', `${sites?.site_list.find(site => site.name.includes(process.env.REACT_APP_SITE_DOMAIN))?.name}`], () => site.overview(sites?.site_list.find(site => site.name.includes(process.env.REACT_APP_SITE_DOMAIN))?.name), {
    enabled: !!sites?.site_list.length
  });

  const plan = siteOverview?.plan?.current_plan;
  const { setIsModalOpen, isModalOpen, renderComponent, handleAccountModal, handleBack, key } = useTopbarMenu()

  return (
    <>
      {plan?.name !== "pro" && plan && (
        <>
          <UpgradeLogo onClick={() => handleAccountModal("Upgrade")}/>
          <AccountModal
            open={isModalOpen}
            onClose={setIsModalOpen}
            title={key}
            onClickBack={handleBack} 
            component={renderComponent()}
          />
        </>
      )}
    </>
  );
}

export default UpgradeProButton;
