import { Globe2, LoaderIcon } from "lucide-react"
import { useState, useEffect } from "react";
import WorkspaceTabs from "src/components/workspace/WorkspaceTabs"
import { anotherWorkspaceList, workspaceAdvantages, workspaceTriggers } from "src/components/workspace/workspaceLists"
import WorkspaceBanner from "src/components/workspace/WorkspaceBanner"
import useWorkspaces from "src/hooks/useWorkspaces"
import WorkspaceCard from "src/components/workspace/WorkspaceCard"
import ScrollArrows from "src/components/ScrollArrows"
import { Link, useParams } from "react-router-dom"
import { useMutation, useQuery } from "react-query";
import { site } from "../../client/api";
import { useUser } from "../../hooks/useUser";
import { Dialog, DialogPanel } from '@headlessui/react'
import AdvantageCard from "src/components/AdvantageCard";
import { setLoginSource } from "../../utils/helper";
import { usePostHog } from "posthog-js/react";


const WorkspacePage = () => {
  const [websid, setwebsid] = useState("")
  const { user } = useUser();
  const [isOpen, setIsOpen] = useState(false)
  const [siteName, setSiteName] = useState("");
  const posthog = usePostHog();

  // useEffect(() => {
  //   const storedWebsid = localStorage.getItem("sso_token");
  //   if (storedWebsid) {
  //     const { websid, siteName } = JSON.parse(storedWebsid);
  //     setwebsid(websid);
  //     setSiteName(site);
  //   }
  // }, []);
  useEffect(() => {
    if (websid) {
      localStorage.setItem("sso_token", JSON.stringify({ websid, site }));
    }
  }, [websid]);


  const { data: sites } = useQuery('sites', site.list, {
    enabled: !!user,
  });


  const { mutate: loginAsAdmin } = useMutation('loginAsAdmin', ({ name, reason, pageid }) => site.login(name, reason), {
    onSuccess: (res, variables) => {
      const { sid, site } = res.data.message;
      const { pageid } = variables;
      if (sid && site) {
        console.log(sid);
        setwebsid(sid);
        window.open(`https://${site}/app/${pageid}?sid=${sid}&posthog_session_id=${posthog.get_session_id()}`, "_self");
        setIsOpen(false)
      }

    }
  });
  const loginNow = (page) => {
    setIsOpen(true)
    var sitetoview = sites?.site_list.find(site => site.name.includes(process.env.REACT_APP_SITE_DOMAIN))?.name;
    setLoginSource(sitetoview);

    if (page !== undefined) {
      if (websid == "") {
        loginAsAdmin({ name: sitetoview, reason: "Login", pageid: page });
      }
      else {
        window.open(`https://${sitetoview}/app/${page}?sid=${websid}&posthog_session_id=${posthog.get_session_id()}`, "_self");
        setIsOpen(false)
      }
    }
  }

  const { type } = useParams()
  const { heading, website } = useWorkspaces(type)

  const filterAnotherWorkspaceList = anotherWorkspaceList.filter(data => data.type == (type === undefined ? "workspace" : type))
  const filterWorkspaceAdvantages = workspaceAdvantages.filter(data => data.section == (type === undefined ? "workspace" : type))

  return (
    <main className="flex flex-col gap-y-12 main-container">
      <Dialog transition open={isOpen} onClose={() => setIsOpen(false)} className="z-99 fixed inset-0 flex w-screen items-center justify-center bg-black/30 p-4 transition duration-300 ease-out data-[closed]:opacity-0" >
        <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
          <div className="flex gap-4">
            <Dialog.Panel className={`max-w-lg space-y-4 border bg-white p-6 rounded-full ${isOpen ? "fade-in-loading-workspace" : "opacity-0"}`}>
              <LoaderIcon className="animate-spin" />
            </Dialog.Panel>
          </div>
        </div>
      </Dialog>

      <WorkspaceTabs type={type} triggerType={workspaceTriggers} />

      {filterWorkspaceAdvantages.length > 0 &&
        <section className={`grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-6 gap-y-5`}>
          {filterWorkspaceAdvantages.map((advantage, index) => (
            <AdvantageCard key={index} onClick={() => loginNow(advantage.link)} icon={advantage.icon} title={advantage.title} desc={advantage.desc} />
          ))}
        </section>
      }

      {type === undefined ? (
        <WorkspaceBanner />
      ) : null}
    </main>
  )
}

export default WorkspacePage